export const CATEGORY_INITIAL_FORM_VALUE = {
    name: "",
    description: "",
    isRoot: false,
};

export const ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE = {

    material_code: "",
    material_short_name: "",
    material_long_description: "",
    uom: null,  // Dropdown selection for Unit of Measurement (UoM)
    material_type: null,  // Dropdown selection for Material Type
    price_per_unit: "",  // Optional
    stock_type: null,  // Dropdown selection for Stock Type (e.g., In Stock, Out of Stock)
    supplier: "",  // Optional
    reorder_level: "",  // Optional
    lead_time: "",  // Optional
    material_group: "",  // Optional
    batch_number: "",  // Optional
};

export const CATEGORY_REQUIRED_FIELDS = [
    "name"
];

export const CATEGORY_ERROR_MESSAGE_VALUES = {
    name: ""
};

export const PRODUCT_INITIAL_FORM_VALUE = {
    name: "",
    description: "",
    originalPrice: 0,
    stock: 0,
    code: ""
};

export const PRODUCT_ERROR_MESSAGE_VALUES = {
    name: "",
    description: "",
    code: ""
    //originalPrice: "", Not Required
    //stock: "" Not Required
};

export const ADD_STOCK_ERROR_MESSAGE_VALUES = {
    itemCode: "",       // For the 'Item Code' select field
    itemName: "",       // For the 'Item Name' input field
    poReference: "",    // For the 'PO Reference' input field
    quantity: "",       // For the 'Quantity' input field
    uom: "",            // For the 'UOM' input field
    price: ""           // For the 'Price (As per PO)' input field //originalPrice: "", Not Required
    //stock: "" Not Required
};

export const PRODUCT_REQUIRED_FIELDS = [
    "name",
    "description",
    //"originalPrice", Not Required
    //"stock" Not Required
];
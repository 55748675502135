import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import InputField from '../InputField';

const Dropdown = ({ options, selectedOption = 0, onOptionSelect, label, parentClass, required = false, id, name, height = "200px", alignment = "text-left", searchInput = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [optionsList, setOptionsList] = useState([]);
    const [originalOptionsList, setOriginalOptionsList] = useState([]);
    const [showSearchInput, setShowSearchInput] = useState(searchInput);
    const [searchText, setSearchText] = useState("");
    const [searchErrorMessage, setSearchErrorMessage] = useState("");

    const handleOptionClick = (option, id) => {
        onOptionSelect(option, id);
        setIsOpen(false);

        setSearchText("");
        setSearchErrorMessage("");
        setOptionsList(originalOptionsList);
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setSearchText(value);
        const updatedOptions = originalOptionsList.filter(i => {
            if (i?.label) {
                return i?.label?.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
            }
            if (i?.name) {
                return i?.name?.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
            }
        });
        if (updatedOptions.length > 0) {
            setSearchErrorMessage("");
            setOptionsList(updatedOptions);
        } else {
            setOptionsList([]);
            setSearchErrorMessage("No Options Matched");
        }
    };

    useEffect(() => {
        if (options.length > 10) {
            setShowSearchInput(true);
        }
        setOptionsList(options);
        setOriginalOptionsList(options);
    }, [options])

    return (
        <div className={`relative ${parentClass}`}>
            <label className={`block text-base text-black text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>}</label>
            <button
                   type='button'
                className="inline-flex items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border border-gray-400"
                onClick={() => setIsOpen(!isOpen)}
                title={optionsList[selectedOption - 1]?.label ?? selectedOption?.name ?? "Please Select"}
            >
                {optionsList[selectedOption - 1]?.label ?? selectedOption?.name ?? "Please Select"}
                <FaAngleDown className="ml-2" />
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-[12px]">
                    <ul className="py-2" style={{ height: 'auto', maxHeight: '200px', overflow: "auto" }}>
                        {
                            showSearchInput && (
                                <li key="searchInput" className="px-1 py-1">
                                    <InputField
                                        name="searchInput"
                                        placeholder="Search Options"
                                        value={searchText}
                                        onChange={handleInputChange}
                                    />
                                    {searchErrorMessage && <p className="text-red-500 text-[10px] mt-1 ml-1 text-left">{searchErrorMessage}</p>}
                                </li>
                            )
                        }
                        {optionsList.map((option, index) => (
                            <li key={`${option.value}-${index}`} className="px-4 py-2 hover:bg-gray-100">
                                <button
                                   type='button'
                                    className="w-full text-left focus:outline-none"
                                    onClick={() => handleOptionClick(option, id)}
                                    id={id}
                                >
                                    {option.label ?? option.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;

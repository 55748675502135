import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { ColorRing } from "react-loader-spinner";
import { set, cloneDeep, get, isEmpty } from "lodash";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import ErrorMessage from "@/components/ErrorMessage"
import ContentHeader from "../ContentHeader";
import { useData } from "@/hooks/useData";

const INITIAL_FORM_VALUE = {
    status: []
};

const ERROR_MESSAGE_VALUES = {
    status: "",
};

const REQUIRED_FIELDS = [
    "status"
];

const UpdateWorkOrderStatusPopup = ({ togglePopup, isOpen, order, onUpdateStatus, isAdmin }) => { 
    const [queryParams] = useSearchParams();

    const { data } = useData();
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGE_VALUES);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statusList, setStatusList] = useState([]);

    const handleSelectChange = (selectedValue) => {
        setFormValues((prevState) => ({
            ...prevState,
            status: selectedValue.label,
        }));
        setSelectedStatus(selectedValue);
    };

    const setWorkOrderStatus = async () => {
        setIsLoading(true);
        if (!isEmpty(order)) {
            if (!isEmpty(data.orderStatus)) {
                const statusList = data.orderStatus?.map((item, index) => {
                    return { label: item?.statusName, value: item?.id, index }
                });
                setStatusList(statusList);

                let status = statusList.find(item => item.label === order.status);
                if (status) {
                    const updatedStatusList = statusList.map((item, index) => {
                        return { ...item, isDisabled: (item.index <= status.index) && !isAdmin };
                    });
                    setStatusList(updatedStatusList);
                    setSelectedStatus(status);
                };
            };
        };
        setIsLoading(false);
    };

    const handleSubmit = async () => {
        setIsPending(true);
        let newErrorMessages = {};
        REQUIRED_FIELDS.forEach((field) => {
            if (!get(formValues, field) || isEmpty(get(formValues, field))) {
                newErrorMessages[field] = "This field is required.";
            }
        });
        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            setIsPending(false);
            toast.error("Please fill mandatory fields");
            return;
        };

        let payload = { status: formValues.status };
        onUpdateStatus(payload);
    };

    useEffect(() => {
        if (data.orderStatus) {
            setWorkOrderStatus();
        }
    }, [data]);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-5 rounded shadow-lg"
            >
                <div className="">
                    <span className="text-[14px] text-red-500 font-small pt-3.5 pl-3.5">Change Status for Order Id: {order.orderId}</span>
                    <div className="grid grid-cols-1 p-5">
                        <div className="mb-2">
                            <p className='block text-black text-[12px] text-left'>Work Order Status <span className="text-red-500 text-[12px] font-extrabold">*</span></p>
                            <Select
                                options={statusList}
                                value={selectedStatus}
                                name="status"
                                placeholder="Select status..."
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        borderColor: 'rgb(156 163 175)'
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        textAlign: 'left',
                                        fontSize: '12px',
                                    })
                                }}
                                onChange={handleSelectChange}
                                isSearchable={true}
                                required={true}
                            />
                            {get(errorMessages, "status") && <ErrorMessage />}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 pl-3 pt-0">
                        {(!isPending) &&
                            <div className="flex p-2.5 gap-2">
                                <Button text="Update" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                                <Button text="Cancel" textColor="white" bgColor="[#39ACE4]" onClick={() => togglePopup()} />
                            </div>
                        }
                        {isPending && <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                        />}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UpdateWorkOrderStatusPopup;
import { forwardRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendarWeek } from "react-icons/bs";
import "./DatePickerComponent.css"

const CustomDatePickerInput = forwardRef(({ value, onClick, name, disabled = false }, ref) => (
    <div className="date-picker-input">
        <input
            type="text"
            value={value}
            onClick={onClick}
            placeholder="Select a date"
            readOnly
            ref={ref}
            className={`inline-flex outline-0 items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border ${disabled ? "border-gray-300 text-gray-400" : "border-gray-400"}`}
        />
        <BsCalendarWeek className="date-picker-icon text-gray-600" />
    </div>
));

// CustomDatePickerInput.propTypes = {
//     value: PropTypes.oneOf([PropTypes.instanceOf(Date), null, undefined]),
//     onClick: PropTypes.func,
//     name: PropTypes.string,
//     disabled: PropTypes.bool
// };


const MyDatePicker = ({ name, selectedDate, handleDateChange, label, disabled = false, alignment = "text-left", required = false, showTimeSelect = false }) => {
    
    const onChange = (date) => {
        handleDateChange(name, date);
    };
    
    return (
        <>
            {label && <label className={`block text-black text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>} </label>}
            <div
                className="border-gray-400 relative"
                data-te-datepicker-init
                data-te-inline="true"
                data-te-input-wrapper-init>
                <DatePicker
                    name={label}
                    selected={selectedDate}
                    onChange={onChange}
                    dateFormat={showTimeSelect ? 'dd-MM-yyyy h:mm aa' : 'dd-MM-yyyy'}
                    customInput={<CustomDatePickerInput disabled={disabled} />}
                    disabled={disabled}
                    showTimeSelect={showTimeSelect}
                />
            </div>
        </>
    );
};

MyDatePicker.propTypes = {
    name: PropTypes.string,
    selectedDate: PropTypes.instanceOf(Date),
    handleDateChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    alignment: PropTypes.string,
    showTimeSelect: PropTypes.bool
};

export default MyDatePicker

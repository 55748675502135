import React from "react";

const InputField = ({ name, label, placeholder = "", value, onChange, disabled = false, required = false, alignment = "text-left", onBlur = () => {}, onKeyPress = () => {},maxLength }) => {
  const inputType = (name ==="passCode") ? "password" : "text";
  
  return (
    <div>
      {label && <label className={`block text-black text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>} </label>}
      <input
        name={name}
        type={inputType}
        className={`inline-flex outline-0 items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border border-gray-400 ${disabled ? "opacity-50" : ""}`}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        required={required}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onKeyDown={(e) => onKeyPress(e)}
        disabled={disabled}
        title={value}
      />
    </div>
  );
};

export default InputField;

export const WORK_ORDER_PAYMENT_LIST_COLUMN  = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 2
    },
    {
        Header: "Date",
        accessor: "lastUpdated",
        width: 10
    },
    {
        Header: "Order Id",
        accessor: "orderId",
        width: 10
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        width: 10
    },
    {
        Header: "Account",
        accessor: "salesPersonName",
        width: 10
    },
    {
        Header: "Debit Amt",
        accessor: "debitAmount",
        width: 25
    },
    {
        Header: "Credit Amt",
        accessor: "creditAmount",
        width: 25
    },
    {
        Header: "Closing Amount",
        accessor: "closingAmount",
        width: 25
    }
];

export const RECEIPT_LIST_COLUMN = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 1
    },
    {
        Header: "Receipt No.",
        accessor: "receiptNumber",
        width: 2
    },
    {
        Header: "Date",
        accessor: "lastUpdated",
        width: 10
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        width: 10
    },
    {
        Header: "Payment Mode", accessor: "bankName", width: 25, Cell: ({ row }) => {
            const { modeOfPayment, bankName } = row.original;
            return <span className="capitalize">{`${modeOfPayment}, ${bankName}`}</span>;
        }
    },
    {
        Header: "Amount",
        accessor: "paidAmount",
        width: 25
    },
    {
        Header: "Payment Ref. No",
        accessor: "checkNumber",
        width: 25
    },
    {
        Header: "Created By",
        accessor: "createdBy",
        width: 25
    },
    {
        Header: "Additional Info",
        accessor: "additionalInformation",
        width: 25
    }
];

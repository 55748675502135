const USERMODEL = {
    familyName: "",
    givenName: "",
    passCode: "",
    contactMedium: {
        email: "",
        phoneNumber: ""
    },
    roleId: [],
    userId: ""
};

const ROLEMODEL = {
    name: "",
    description: "",
};

const PASSWORDMODEL = {
    password: "",
};

const PERMISSIONMODEL = {
    moduleName: "",
    path: "",
    roleId: "",
    userAccess: {
        delete: false,
        read: true,
        write: false
    }
}

export const INITIAL_USER_FORM_VALUE = USERMODEL;

export const USER_ERROR_MESSAGE_VALUES = { ...USERMODEL, roleId: "" };

export const USER_REQUIRED_FIELDS = ["familyName", "givenName", "passCode", "contactMedium.email", "contactMedium.phoneNumber", "roleId", "userId"];

export const INITIAL_ROLE_FORM_VALUE = ROLEMODEL;

export const ROLE_ERROR_MESSAGE_VALUES = ROLEMODEL;

export const ROLE_REQUIRED_FIELDS = ["name", "description"];

export const INITIAL_PWD_FORM_VALUE = PASSWORDMODEL;

export const PWD_ERROR_MESSAGE_VALUES = PASSWORDMODEL;

export const PWD_REQUIRED_FIELDS = ["password"];

export const INITIAL_PERMISSION_FORM_VALUE = PERMISSIONMODEL;

export const PERMISSION_ERROR_MESSAGE_VALUES = { ...PERMISSIONMODEL, userAccess: ""};

export const PERMISSION_REQUIRED_FIELDS = ["moduleName", "roleId", "userAccess"];

export const MODULES_LIST = [
    { name: 'Dashboard', id: 'Dashboard' },
    { name: 'User Management', id: 'User Management' },
    { name: 'Sales Department', id: 'Sales Department' },
    { name: 'Sales Enquiry', id: 'Sales Enquiry' },
    { name: 'Sales Work Order', id: 'Sales Work Order' },
    { name: 'Order Receipts', id: 'Order Receipts' },
    { name: 'Design Department', id: 'Design Department' },
    { name: 'Production Department', id: 'Production Department' },
    { name: 'Quality Dispatch', id: 'Quality Dispatch' },
    { name: 'Account Department', id: 'Account Department' },
    { name: 'Add Payment', id: 'Add Payment' },
    { name: 'Sales Balance', id: 'Sales Balance' },
    { name: 'Work Order Approval', id: 'Work Order Approval' },
    { name: 'Quotation Template', id: 'Quotation Template' },
    { name: 'Reports', id: 'Reports' },
    { name: 'Masters', id: 'Masters' },
    { name: 'Category', id: 'Category' },
    { name: 'Add Inventory', id: 'Add Inventory' },
    { name: 'Product', id: 'Product' },
    { name: 'Settings', id: 'Settings' }
];

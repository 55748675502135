import React, { useState, useEffect } from "react";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Blocks } from "react-loader-spinner";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "@/pages/ContentHeader";
import AddProduct from "./AddProduct";
import { INVENTORY_STOCK_ADDED_HEADER  } from "./Headers";

import { APIService } from "@/services";
import { productApi, categoryApi,productInventoryApi,stockInventoryApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { rolePermissionChecker, filterInventryData, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";
import AddProductStock from "./AddProductStock";

const ProductStock = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [productList, setProductList] = useState(null);
    const [inventoryStockList, setInventoryList] = useState([]);
    const [itemCodeList,setItemCodeList] = useState(null);
    const [uomList, setUOMList] = useState([{label:'HP',value:'HP'},{label:'Nm',value:'Nm'},{label:'lb-ft', value:'lb-ft'},{value:'liters',label:'liters'},{value: 'CC',label:'CC'}]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [productListChanged, setProductListChanged] = useState(false)
    const [selectedId, setSelectedId] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
    getInventoryProduct();
    
    },[productListChanged]);

    const togglePopup = () => {
        setSelectedRow({});
        setIsOpen(!isOpen);
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${stockInventoryApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setProductListChanged(!productListChanged);
        setIsLoading(false);
    };

    const modifiedData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.code
        }));
    };
    const getInventoryProduct = async () => {
        setIsLoading(true);
        const response = await APIService.get(productInventoryApi.get);
        var  updatedList = await addSerialNumbersToList(response.productInventoryList);
        updatedList = Array.from(updatedList).map(function(e){
            let t = e;
           if(e['isScrapGoods'] == 'undefined'){
            t = e;
                t['isScrapGoods'] = 'false';
           }
           else if(e['isScrapGoods']){
                 t = e;
                t['isScrapGoods'] = 'Yes';
            }
            else{
                t = e ;
                t['isScrapGoods'] = "No"
            }
            return t; 
        });
      const stockResponse =   await APIService.get(stockInventoryApi.get);
      console.log(stockResponse);
      //  setIsOpenDeleteModal(!isOpenDeleteModal);
        updatedList = Array.from(updatedList).sort((a, b) => Date.parse(b['lastUpdated']) - Date.parse(a['lastUpdated']));
       //  modifiedData(updatedList)
       // console.log("productList"+JSON.fstringify(updatedList));
        setItemCodeList(modifiedData(updatedList));
        setProductList(updatedList);
    
        setInventoryList(stockResponse['productInventoryStockEntities']);
        setIsLoading(false);    
    };
    
    const getProduct = async () => {
        setIsLoading(true);
        const response = await APIService.get(productApi.filter);
        //const updatedList = await addSerialNumbersToList(response.productList);
       // setProductList(updatedList);
        setIsLoading(false);
    };

    const getCategoryFilterData = async () => {
        const response = await APIService.get(categoryApi.filterCategory);
        setCategoryNameList(modifiedData(response.categoryList) || []);
        
    };

    // useEffect(() => {
    //     getProduct();
    //     //getCategoryFilterData();
    // }, [productListChanged]);

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            const id = rowData?.id
            setSelectedRow(rowData);
            setIsOpen(true);
            setSelectedId(id);
        }
    };

    const columnsArray = [
        ...INVENTORY_STOCK_ADDED_HEADER,
        {
            Header: "Status",
            accessor: "status",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <TrashIcon {...icon} onClick={() => handleActionClick("delete", row.original)} />
                    <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original)} />
                </div>
            ),
        },
    ];

    const onAddProductSubmit = async (data, action) => {
        let response;
        alert(JSON.stringify(data));
        if (action === "post") {
            response = await APIService.post(stockInventoryApi.post, data);
        } else if (action === "patch") {
            response = await APIService.patch(`${stockInventoryApi.patch}${selectedId}`, data);
        }

        if (response.status === 200 || response.status === 201) {
            toast.success("Product saved successfully");
        } else {
            toast.error("Error while saving product!");
            return;
        }

        setIsOpen(!isOpen);
        setProductListChanged(!productListChanged);
    };

    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target
        const filteredOrders = filterInventryData(value, productList);
        if (filteredOrders.length === 0) {
            setProductListChanged(!productListChanged);
        } else {
            setProductList(filteredOrders ?? []);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader title="Inventory" description="Inventory" showHeader={true} showDatePicker={false} showSearchInput={false} showButton={false} />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        {isOpen && <AddProductStock togglePopup={togglePopup} isOpen={isOpen} uomList={uomList} onAddProductSubmit={onAddProductSubmit} selectedRow={selectedRow} itemCodes={itemCodeList} productList={productList} />}
                        {isOpenDeleteModal && selectedId && <DeleteConfirmationModal handleDelete={handleDelete} isOpen={isOpenDeleteModal} togglePopup={toggleDeletePopup} id={selectedId} />}
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} />
                            </div>
                            <div className="flex">
                                <div className="pr-[2px]">
                                    <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, inventoryStockList, "InventoryStockList") }} />
                                </div>
                                <div>
                                    {<Button text="Add Stock" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePopup} />}
                                </div>
                            </div>
                        </div>
                        {inventoryStockList && <PaginationTable columnsData={columnsArray} rowData={inventoryStockList} />}
                    </div>
                </>
            )}
        </>
    );
}

export default ProductStock;


import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { get } from "lodash";
import { HiOutlineMail, HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";

import { useAuth } from "@/hooks/useAuth";
import { APP_URLS } from "@/routes";
import { 
    INITIAL_LOGIN_FORM_VALUE,
    LOGIN_REQUIRED_FIELDS,
    LOGIN_ERROR_MESSAGE_VALUES,
} from "../constant";

import "./Login.css";
const baImage = "/images/login_bg.jpg";

const Login = () => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState(INITIAL_LOGIN_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(LOGIN_ERROR_MESSAGE_VALUES);

    useEffect(() => {
        if (auth.token && auth.user) {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`);
        }
    }, []);

    const handleChange = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrorMessages = {};
        const requiredFields = LOGIN_REQUIRED_FIELDS;
        requiredFields.forEach((field) => {
            if (!get(formValue, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            return;
        };
    
        let { username, password } = document.forms[0];

        setLoading(true);
        let payload = { username: formValue.username, passCode: formValue.password };
        if (username.value && password.value) {
            let response = await auth.doLogin(payload);
            setLoading(false);
            if (response) {
                toast.success("Successfully Login");
                handleRedirect();
            } else {
                // toast.error("Incorrect Login Credentials");
            }
        } else {
            setLoading(false);
            toast.error("Please enter valid credentials");
        }
    };

    const handleRedirect = () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const isAdmin = user.roleList.indexOf("1") !== -1;
        const isSales = user.roleList.indexOf("2") !== -1;
        const isDesigner = user.roleList.indexOf("3") !== -1;

        if (isAdmin) {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`);
        } else if (isSales) {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_LIST}`);
        } else if (isDesigner) {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.DESN_DEPT}`);
        } else {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`);
        }
    };

    return (
        <div className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{
                backgroundImage: `url(${baImage})`
            }}>
            <div className="flex flex-col">
                <div className="flex flex-row items-center logos mb-2">
                    <img src="/images/autocraft_group.png" className="border-r-2 border-gray-300" />
                    <img src="/images/autocraft_trailers.png" />
                </div>
                <div className="w-full max-w-md p-8 rounded-lg bg-[#39ACE4]">
                    <h1 className="text-white text-3xl font-semibold text-center mb-2">Login into account</h1>
                    <div className="text-white text-[10px] text-gray-500 font-normal text-center mb-8">Use your credentials to access your account.</div>
                    <form className="space-y-6">
                        <div className="flex items-center rounded-md bg-white border border-gray-300">
                            <span className="inline-flex items-center justify-center rounded-l-md px-2">
                                <HiOutlineMail className="w-6 h-6 text-gray-400" />
                            </span>
                            <input
                                type="username"
                                id="username"
                                name="username"
                                className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                                placeholder="Email Address"
                                value={formValue.username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {errorMessages.username && <p className="text-red-500 text-left text-[12px]">{errorMessages.username }</p>}
                        <div className="flex items-center rounded-md bg-white border border-gray-300">
                            <span className="inline-flex items-center justify-center rounded-l-md px-2">
                                {!showPassword && <HiOutlineEyeOff className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowPassword(true)} />}
                                {showPassword && <HiOutlineEye className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowPassword(false)} />}
                            </span>
                            <input
                                type={!showPassword ? "password": "text"}
                                id="password"
                                name="password"
                                className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                                placeholder="Password"
                                value={formValue.password}
                                onChange={handleChange}
                                required
                                autoComplete="off"
                            />
                        </div>
                        {errorMessages.password && <p className="text-red-500 text-left text-[12px]">{errorMessages.password }</p>}
                        {!loading && <button
                            className="w-full bg-[#FF9220] hover:bg-orange-500 text-white font-bold py-2 px-4 rounded-lg h-12"
                            onClick={handleSubmit}
                            type="submit"
                        >
                            Login
                        </button>}
                        {loading && <div className="grid justify-items-center">
                            <ColorRing
                                visible={loading}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper text-center"
                                colors={["#FF9220", "#FF9220", "#FF9220", "#FF9220", "#FF9220"]}
                            />
                        </div>}
                    </form>
                    <p className="text-right text-white text-sm mt-4 text-[14px]">
                        <Link to="/password/reset">
                            Forgot Password?
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { set, cloneDeep, isEmpty, get, pull, concat } from "lodash";
import { ADD_STOCK_ERROR_MESSAGE_VALUES } from "./constants";

import InputField from "@/components/InputField"
import Button from "@/components/Button";
import { Alert } from "react-bootstrap";
Modal.setAppElement("#root");

const AddProductStock = ({ togglePopup, isOpen, uomList, onAddProductSubmit, selectedRow, itemCodes,productList }) => {
  //  const [product, setProduct] = useState(!isEmpty(selectedRow) ? selectedRow : PRODUCT_INITIAL_FORM_VALUE);
//alert(JSON.stringify(selectedRow));
   const [selectedOptions, setSelectedOptions] = useState(!isEmpty(selectedRow) ? { value: itemCodes.find((e)=>selectedRow.code == e.label).value, label: itemCodes.find((e)=>selectedRow.code == e.label).label, } : []);
   const [errorMessages, setErrorMessages] = useState(ADD_STOCK_ERROR_MESSAGE_VALUES);
   // const defaultRow = location.state ? location.state.selectedRow : ADD_INVENTORY_PRODUCT_INITIAL_FORM_VALUE;
    const isEmptySelectedRow = isEmpty(selectedRow) ;
    let findElement = !isEmpty(selectedRow) ? productList.find(item=> item.code == selectedRow.code):{};
   
   const handleOptionSelect = (selectedOptions) => {
      //alert("Hi");
        setSelectedOptions(selectedOptions);
      //  console.log(productList);
       findElement =  productList.find(item=> item.code == selectedOptions.label);
      console.log("findElement"+JSON.stringify(findElement)); 
         setFormValues({
          itemName:findElement['name'],
          itemCode:selectedOptions.label,
          poReference:formValues.poReference,
          quantity:formValues.quantity,
          uom:findElement['purchaseUnit'],
          price:formValues.price 
        })

    };
    function getFieldValue(field) {
        return isEmptySelectedRow ? "" : findElement?.[field] ?? "";
      }

    const [formValues, setFormValues] = useState(()=>({
      itemCode: !isEmpty(selectedOptions) ? selectedOptions['label']:'', // 'Item Code' select field
      itemName: getFieldValue('name'), // 'Item Name' input field
      poReference: selectedRow.poNumber, // 'PO Reference' input field
      quantity: selectedRow?.quantity || '', // 'Quantity' input field
      uom: getFieldValue('purchaseUnit'), // 'UOM' input field
      price: selectedRow?.price || '', // 'Price (As per PO)' input field
    }));
  
  const handleSubmit = () => {
    // Define your required fields      
    const requiredFields = ["itemCode", "itemName", "poReference", "quantity", "uom", "price"];
    
    // Create an object to hold error messages
    const newErrorMessages = {};

    // Check each required field
    requiredFields.forEach((field) => {
        if (!formValues[field]) {
            newErrorMessages[field] = "This field is required.";
        } else {
            newErrorMessages[field] = ""; // Clear any previous error message
        }
      });
    // Set the error messages state
    setErrorMessages(newErrorMessages);

    // Check if there are any error messages
    if (Object.values(newErrorMessages).some(error => error !== "")) {
        toast.error("Please fill all mandatory fields."); // Show an error toast
        return; // Stop the submission process
    }

    // If all required fields are filled, proceed with submission
    const payload = {
      code: formValues.itemCode,
      poNumber: formValues.poReference,
      quantity: parseInt(formValues.quantity), 
    };
        //alert(selectedRow);
    // Call your submission function
    onAddProductSubmit(payload,  !isEmpty(selectedRow) ? "patch" : "post");
};

 const handleCancel = ()=>{
 togglePopup();    
 }

  const handleInputChange = (name, value) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
};

   
    return (
        <div>
            <Modal
                isOpen={isOpen}
                 onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg"
            >
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Add Stock</h2>
                        {/* <form className="space-y-4" > */}
                            <div>
                                <label className="block text-sm font-medium">Item Code</label>
                                <Select options={itemCodes} placeholder="Select Item Code" 
                                isSearchable={true}
                                value={selectedOptions}
                                required={true}
                                onChange={handleOptionSelect} />
                            </div>
                            <InputField 
                                name="name" 
                                label="Item Name" 
                                disabled={true}
                                placeholder="Enter Item Name" 
                                value={formValues.itemName} 
                              
                              
                            />
                            <InputField 
                                name="poReference" 
                                label="PO Reference" 
                                required = {true}
                                placeholder="Enter PO Reference" 
                                value={formValues.poReference} 
                                onChange={(e) => handleInputChange("poReference", e.target.value)}
                                
                            />
                            <InputField 
                                name="quantity" 
                                label="Quantity" 
                                required = {true}
                                placeholder="Enter Quantity" 
                                value={formValues.quantity || ""} 
                                onChange= {(e) => handleInputChange("quantity", e.target.value)}
                                type="number" 
                            />
                            <InputField 
                                name="uom" 
                                disabled={true}
                                label="UOM" 
                                placeholder="Enter UOM" 
                                value={formValues.uom} 
                              
                            />
                            <InputField 
                                name="price" 
                                required = {true}
                                label="Price (As per PO)" 
                                placeholder="Enter Price" 
                                value={formValues.price || ""} 
                                onChange= {(e) => handleInputChange("price", e.target.value)}
                                type="number" 
                            />
                            <div className="flex gap-2 mt-2">
    <Button 
        text="Submit" 
        textColor="white" 
        bgColor="[#39ACE4]" 
         onClick={handleSubmit} 
    />
    <Button 
        text="Cancel" 
        textColor="Black" 
        bgColor="White" 
        onClick={handleCancel}
    />
</div>

                        {/* </form> */}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddProductStock;

/* const AddProductStock = ({ togglePopup, isOpen, uomList, onAddProductSubmit, selectedRow,itemCodes }) => {
    const [product, setProduct] = useState(!isEmpty(selectedRow) ? selectedRow : PRODUCT_INITIAL_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(PRODUCT_ERROR_MESSAGE_VALUES);
    const [selectedOptions, setSelectedOptions] = useState(!isEmpty(selectedRow) ? { value: selectedRow.categoryId, label: selectedRow.categoryName } : []);
    let PRODUCT_FORM_REQUIRED_FIELDS = PRODUCT_REQUIRED_FIELDS;
    // const itemCodes = [
    //     { value: 'ITEM001', label: 'ITEM001' },
    //     { value: 'ITEM002', label: 'ITEM002' },
    //     { value: 'ITEM003', label: 'ITEM003' },
    //     { value: 'ITEM004', label: 'ITEM004' }
    //   ];
    
    // define the function to handle option selection
    const handleOptionSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(product);
        set(updatedState, name, value);
        setProduct(updatedState);
    };

    const makeDependentFieldsMandatory = () => {
        if (!isEmpty(selectedOptions)) {
            if (selectedOptions.label === "Trailer Type" && selectedOptions.value === "01") {
                PRODUCT_FORM_REQUIRED_FIELDS = concat(PRODUCT_FORM_REQUIRED_FIELDS, "code");
            } else {
                pull(PRODUCT_FORM_REQUIRED_FIELDS, "code");
            }
        };
    };

    const handleSubmit = async () => {
        makeDependentFieldsMandatory();

        const newErrorMessages = {};
        PRODUCT_FORM_REQUIRED_FIELDS.forEach((field) => {
            if (!get(product, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "originalPrice" || field === "stock") {
                    if (!get(product, field).toString().match(REGEX.WHOLE_NUMBER)) {
                        newErrorMessages[field] = "Please enter valid input";
                    }
                };
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            toast.error("Please fill mandatory fields");
            return;
        }

        const payLoad = {
            name: product?.name,
            description: product?.description,
            originalPrice: product?.originalPrice,
            stock: product?.stock,
            code: product?.code
        };

        if (!isEmpty(selectedRow)) {
            const payload = {
                name: product?.name,
                description: product?.description,
                categoryId: selectedOptions.value,
                originalPrice: 1.0,
                stock: product?.stock,
                code: product?.code
            };

            //const data = selectedOptions ? { ...payload, productCode: product?.name } : payload;
            const data = { ...payload };
            onAddProductSubmit(data, "patch");
        } else {
            const data = selectedOptions ? { ...payLoad, categoryId: selectedOptions.value } : payLoad;
            onAddProductSubmit(data, "post");
        }
    };

    useEffect(() => {
        makeDependentFieldsMandatory();
    }, []);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg"
            >
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-lg font-bold mb-4">Add Stock</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium">Item Code</label>
                <Select options={itemCodes} placeholder="Select Item Code" />
              </div>
              <div>
                <label className="block text-sm font-medium">Item Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Enter Item Name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">PO Reference</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Enter PO Reference"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Quantity</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  placeholder="Enter Quantity"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">UOM</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Enter UOM"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Price (As per PO)</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  placeholder="Enter Price"
                />
              </div>
              <div className="flex gap-2 mt-2">
                    <Button text="Submit" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                    <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                </div>
               </form>
               </div>
               </div>
            
        
                
            </Modal>
        </div>
        

    );
};


export default AddProductStock; */

  
 /* const makeDependentFieldsMandatory = () => {
        if (!isEmpty(selectedOptions) && selectedOptions.label === "Trailer Type" && selectedOptions.value === "01") {
            PRODUCT_FORM_REQUIRED_FIELDS = concat(PRODUCT_FORM_REQUIRED_FIELDS, "code");
        } else {
            pull(PRODUCT_FORM_REQUIRED_FIELDS, "code");
        }
    }; */
/* 
    const handleSubmit = async () => {
        makeDependentFieldsMandatory();
        const newErrorMessages = {};
        
        PRODUCT_FORM_REQUIRED_FIELDS.forEach((field) => {
            if (!get(product, field)) {
                newErrorMessages[field] = "This field is required.";
            } else if (["originalPrice", "stock"].includes(field) && 
                        !get(product, field).toString().match(REGEX.WHOLE_NUMBER)) {
                newErrorMessages[field] = "Please enter valid input";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((msg) => msg !== "")) {
            toast.error("Please fill mandatory fields");
            return;
        }

        const payLoad = { 
            name: product?.name,
            description: product?.description,
            originalPrice: product?.originalPrice,
            stock: product?.stock,
            code: product?.code 
        };

        const data = !isEmpty(selectedRow)
            ? { ...payLoad, categoryId: selectedOptions.value }
            : { ...payLoad, categoryId: selectedOptions.value };
        
        const method = !isEmpty(selectedRow) ? "patch" : "post";
        onAddProductSubmit(data, method);
    }; */

    /* useEffect(() => {
        //makeDependentFieldsMandatory();
    }, []); */

  //   const [formValues, setFormValues] = useState({
  //     code: defaultRow.code,
  //     name: defaultRow.name,
  //     category: selectedOptions,
  //     description: defaultRow.description,
  //     unit: { label: defaultRow.purchaseUnit, name: defaultRow.purchaseUnit },
  //     organization: { label: defaultRow.oranizationName, name: defaultRow.oranizationName,id:defaultRow.id },
  //     goodsType: { label: defaultRow.goodsType, name: defaultRow.goodsType,id:defaultRow.id },
  //     ratePerUnit: defaultRow.perUnitRate,
  //     stockType: { label: defaultRow.stockType, name: defaultRow.stockType },
  //     estimatedLifeValue: defaultRow.estimatedLifeValue,
  //     reOrderLevel: defaultRow.reOrderLevel,
  //     is_scrap_goods: { label: defaultRow.isScrapGoods === "No" ? "no" : "yes", name: defaultRow.isScrapGoods === "No" ? "no" : "yes" },
  //     remark: defaultRow.remark,
  //     batch_number: defaultRow.hsnCode,
  //     additionalInfo: defaultRow.additionalInfo || '',
  //     categoryId: defaultRow.categoryId || '',
  //     categoryName: defaultRow.categoryName || '',
  // });
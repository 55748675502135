import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Blocks, ColorRing } from "react-loader-spinner";
import { set, cloneDeep, get, isEmpty } from "lodash";
import { ToWords } from "to-words";
import { toast } from "react-toastify";

import Dropdown from "@/components/Dropdown";
import Button from "@/components/Button";
import InputField from "@/components/InputField"
import ErrorMessage from "@/components/ErrorMessage"
import ContentHeader from "../ContentHeader";

import { APIService } from "@/services";
import { userApi, orderApi, accountApi } from "@/utils/API";

import {
    INITIAL_FORM_VALUE,
    REQUIRED_FIELDS,
    ERROR_MESSAGE_VALUES,
    PAYMENT_MODES
} from "./constants";
import { APP_URLS } from "@/routes";
import { REGEX } from "@/utils/AppConstants";

const AddWorkOrderPayment = () => { 
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUE);
    const [salesPersonList, setSalesPersonList] = useState([]);
    const [workOrderList, setWorkOrderList] = useState([]);
    const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGE_VALUES);
    const toWords = new ToWords({
        localeCode: "en-IN"
    });

    const id = queryParams.get("id");
    const receiptId = queryParams.get("receiptId");
    const mode = (id && receiptId) ? "edit" : "new";

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(formValues);
        set(updatedState, name, value);
        setFormValues(updatedState);
    };

    const handleOptionSelect = (option, id) => {
        const updatedState = cloneDeep(formValues);
        if (id === "salesPersonId") {
            set(updatedState, id, option.id.trim());
            set(updatedState, "salesPersonName", option.name);
            set(updatedState, "orderId", "");
            getWorkOrderBySalesPerson(option.id.trim());
        } else if (id === "orderId") {
            set(updatedState, id, option.name);
            set(updatedState, "paidAmountSummary.additionalInformation1", option.name.trim());
            set(updatedState, "paidAmountSummary.customerName", option.customerName);
        } else {
            set(updatedState, id, option.id);
        }
        setFormValues(updatedState);
    };

    const selectedOption = (id, options) => {
        return options.find((option) => {
            return option.id === id
        })
    };

    const getWorkOrderBySalesPerson = async (username) => {
        setWorkOrderList([]);
        const response = await APIService.get(orderApi.getParentOrdersIdByUsername(username));
        if (response?.orderList && response?.orderList.length > 0) {
            const workOrderList = response?.orderList.map(item => ({
                name: item.orderId,
                id: item.orderId,
                createdBy: item.createdBy,
                customerName: item.customerName
            }));
            setWorkOrderList(workOrderList);
        }
    };

    const getSalesPersonList = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${userApi.filterUserList}`);
        if (response?.userList && response?.userList.length > 0) {
            const userList = response.userList;

            const userCondition = (item) => {
                return (
                    (item && item.userId && item.familyName && item.givenName) &&
                    (item.roleId.indexOf("2") !== -1 || item.roleId.indexOf("1") !== -1)
                );
            };

            const salePersonList = userList.filter(userCondition).map(item => {
                return {
                    name: `${item.familyName.trim()} ${item.givenName.trim()}`,
                    id: item.userId,
                }
            });
            setSalesPersonList(salePersonList);
        }
        setIsLoading(false);
    };

    const getReceiptData = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${accountApi.get}/${id}`);
        if (!isEmpty(response.status)) {
            const receipt = response.status?.receiptInfo.find(item => item.receiptNumber === receiptId);
            if (!isEmpty(receipt)) {
                patchForm(response.status, receipt);
            }
        } else {
            toast.error("Error while getting payment details");
            redirectToReceiptList();
        }
        setIsLoading(false);
    };

    const patchForm = (data, receipt) => {
        const updatedState = {
            customerName: data.customerName,
            orderId: data.orderId,
            paidAmountSummary: {
                additionalInformation: receipt.additionalInformation,
                additionalInformation1: receipt.additionalInformation,
                bankName: receipt.bankName,
                checkNumber: receipt.checkNumber,
                modeOfPayment: receipt.modeOfPayment,
                paidAmount: receipt.paidAmount,
                receiptNumber: receipt.receiptNumber,
                customerName: receipt.customerName || data.customerName
            },
            salesPersonId: data.salesPersonId,
            salesPersonName: data.salesPersonName
        };
        const updateWorkOrderList = [{
            name: data.orderId,
            id: data.orderId,
            customerName: receipt.customerName || ""
        }];
        setWorkOrderList(updateWorkOrderList);
        setFormValues(updatedState);
    };

    const resetForm = () => {
        setFormValues(INITIAL_FORM_VALUE);
    };

    const handleSubmit = async () => {
        setIsPending(true);

        let newErrorMessages = {};
        REQUIRED_FIELDS.forEach((field) => {
            if (!get(formValues, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "paidAmountSummary.bankName" || field === "paidAmountSummary.paidAmount" || field === "trailerLength") {
                    if (field === "paidAmountSummary.bankName") {
                        if (!get(formValues, field).toString().match(REGEX.ALPHA_SPACE)) {
                            newErrorMessages[field] = "Please enter valid bank name";
                        }
                    }
                    if (field === "paidAmountSummary.paidAmount") {
                        if (!get(formValues, field).toString().match(REGEX.FRACTION)) {
                            newErrorMessages[field] = "Please enter valid amount";
                        }
                    }
                };
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            setIsPending(false);
            toast.error("Please fill mandatory fields");
            return;
        };

        let payload, response;
        if (mode === "edit") {
            const { paidAmountSummary } = formValues;
            payload = {
                additionalInformation: paidAmountSummary.additionalInformation,
                additionalInformation1: paidAmountSummary.additionalInformation,
                bankName: paidAmountSummary.bankName,
                checkNumber: paidAmountSummary.checkNumber,
                modeOfPayment: paidAmountSummary.modeOfPayment,
                receiptNumber: paidAmountSummary.receiptNumber,
                paidAmount: Number(paidAmountSummary.paidAmount),
                amountInWords: toWords.convert(Number(paidAmountSummary.paidAmount), { currency: true }),
                customerName: paidAmountSummary.customerName
            };
            response = await APIService.patch(`${accountApi.patch}updateReceipt/${id}`, payload);
        } else {
            payload = cloneDeep(formValues);
            const paidAmountSummary = {
                ...payload.paidAmountSummary,
                paidAmount: Number(payload.paidAmountSummary.paidAmount),
                amountInWords: toWords.convert(Number(payload.paidAmountSummary.paidAmount), { currency: true })
            };
            set(payload, "customerName", paidAmountSummary.customerName);
            set(payload, "paidAmountSummary", paidAmountSummary);
            response = await APIService.post(accountApi.post, payload);
        }
        setIsPending(false);
        //console.log(payload);
        //return;

        if (response?.status === 200 || response?.status === 201) {
            toast.success(`Payment ${mode === "edit" ? "Updated" : "Added"} Successfully`);
            if (mode === "edit") {
                redirectToReceiptList();
                return;
            }
            resetForm();
        } else {
            const { errorCode } = response?.data; 
            if (errorCode === "missing_mandate_field") {
                toast.error("Please fill mandatory fields");
            } else {
                toast.error(`Error while ${mode === "edit" ? "updating" : "adding"} the payment!!`);
            }
        }
    };

    const redirectToReceiptList = () => {
        const queryParams = `?id=${id}`;
        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.ACC_DEPT_VIEW_RECEIP_LIST}` + queryParams);
    };

    useEffect(() => {
        getSalesPersonList();
        if (mode === "edit") {
            getReceiptData();
        } else {
            resetForm();
        }
    }, [mode]);

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader 
                        title="Account Department"
                        description={`${mode === "edit" ? "Update" : "Add"} Payment`}
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                    />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        <div className="grid grid-cols-4 gap-4 p-5">
                            <div>
                                <Dropdown
                                    options={salesPersonList ?? []}
                                    selectedOption={selectedOption(get(formValues, "salesPersonId"), salesPersonList)}
                                    onOptionSelect={handleOptionSelect}
                                    label="Sales Person"
                                    name="Sales Person"
                                    id="salesPersonId"
                                    required={true}
                                />
                                {get(errorMessages, "salesPersonId") && <ErrorMessage />}
                            </div>
                            <div>
                                <Dropdown
                                    options={workOrderList ?? []}
                                    selectedOption={selectedOption(get(formValues, "orderId"), workOrderList)}
                                    onOptionSelect={handleOptionSelect}
                                    label="Work Order"
                                    name="Work Order"
                                    id="orderId"
                                    required={true}
                                />
                                {get(errorMessages, "orderId") && <ErrorMessage />}
                            </div>
                            <div>
                                <InputField
                                    name="paidAmountSummary.customerName"
                                    label="Customer Name"
                                    value={get(formValues, "paidAmountSummary.customerName")}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                {get(errorMessages, "paidAmountSummary.customerName") && <ErrorMessage />}
                            </div>
                            <div>
                                <InputField
                                    name="paidAmountSummary.bankName"
                                    label="Bank Name"
                                    placeholder="Drawn Bank Name"
                                    value={get(formValues, "paidAmountSummary.bankName")}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                {get(errorMessages, "paidAmountSummary.bankName") && <ErrorMessage error={get(errorMessages, "paidAmountSummary.bankName")} />}
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 p-5">
                            <div>
                                <Dropdown
                                    options={PAYMENT_MODES ?? []}
                                    selectedOption={selectedOption(get(formValues, "paidAmountSummary.modeOfPayment"), PAYMENT_MODES)}
                                    onOptionSelect={handleOptionSelect}
                                    label="Payment Mode"
                                    id="paidAmountSummary.modeOfPayment"
                                    required={true}
                                />
                                {get(errorMessages, "paidAmountSummary.modeOfPayment") && <ErrorMessage />}
                            </div>
                            <div>
                                <InputField
                                    name="paidAmountSummary.paidAmount"
                                    label="Credit Amount"
                                    placeholder="Amount to be paid"
                                    value={get(formValues, "paidAmountSummary.paidAmount")}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                {get(errorMessages, "paidAmountSummary.paidAmount") && <ErrorMessage error={get(errorMessages, "paidAmountSummary.paidAmount")} />}
                            </div>
                            <div>
                                <InputField
                                    name="paidAmountSummary.checkNumber"
                                    label="RTGS/UPI Reference/Cheque No"
                                    placeholder="Payment Reference Number"
                                    value={get(formValues, "paidAmountSummary.checkNumber")}
                                    onChange={handleInputChange}
                                    required={true}
                                    title="RTGS/UPI Reference/Cheque No"
                                />
                                {get(errorMessages, "paidAmountSummary.checkNumber") && <ErrorMessage />}
                            </div>
                            <div>
                                <InputField
                                    name="paidAmountSummary.additionalInformation"
                                    label="Additional Information"
                                    placeholder="Enter payment information"
                                    value={get(formValues, "paidAmountSummary.additionalInformation")}
                                    onChange={handleInputChange}
                                    required={false}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-4 p-3">
                            {(!isPending) &&
                                <div className="flex p-2.5 gap-2">
                                    <Button text={`${mode === "edit" ? "Update" : "Add"} Payment`} textColor="white" bgColor="[#FF9220]" onClick={() => handleSubmit()} />
                                    <Button text="Back" textColor="white" bgColor="[#FF9220]" onClick={() => navigate(-1)} />
                                </div>
                            }
                            {isPending && <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                            />}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AddWorkOrderPayment;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import { EyeIcon } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "@/pages/ContentHeader";
import { BALANCE_INFORMATION_COLUMN } from "./SalesBalanceInformationColumn";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { accountApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { filterBalanceInformation, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";
import { isEmpty } from "lodash";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const SalesBalanceInformation = () => {
    const navigate = useNavigate();
    const { user: auth } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [balanceList, setBalanceList] = useState([]);
    const [originalBalanceList, setOriginalBalanceList] = useState([]);

    const getBalanceInformationData = async () => {
        setIsLoading(true);

        const response = await APIService.get(accountApi.getBalanceInfo);
        setIsLoading(false);
        if (!isEmpty(response)) {
            const updatedList = await addSerialNumbersToList(response);
            setBalanceList(updatedList);
            setOriginalBalanceList(updatedList);
        }
    };

    const filterDataFunction = (searchInput) => {
        const { value } = searchInput?.target
        const filteredBalanceList = filterBalanceInformation(value, originalBalanceList);
        if (filteredBalanceList.length === 0) {
            setBalanceList(originalBalanceList);
        } else {
            setBalanceList(filteredBalanceList ?? []);
        }
    };

    useEffect(() => {
        getBalanceInformationData();
    }, []);
    
    const columnsArray = [
        ...BALANCE_INFORMATION_COLUMN,
        // {
        //     Header: "Action",
        //     accessor: "action",
        //     width: 2,
        //     Cell: ({ value, row }) => {
        //         return (
        //             <div className=" flex justify-center">
        //                 <EyeIcon {...icon} onClick={() => handleActionClick("view", row.original) } title="View Receipt" />
        //             </div>
        //         );
        //     }
        // }
    ];

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Account Department"
                        description="Sales Balance Information"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} />
                            </div>
                            <div className="flex">
                                {   balanceList.length > 0 &&
                                    <div className="pr-[5px]">
                                        <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, balanceList, "Sales_Balance_Information") }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <PaginationTable columnsData={columnsArray} rowData={balanceList} />
                    </div>
                </>
            )}
        </>
    );
}

export default SalesBalanceInformation;
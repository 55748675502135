import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "@/pages/ContentHeader";
import AddCategory from "./AddCategory";
import { CATEGORY_HEADER } from "./Headers";

import { APIService } from "@/services";
import { categoryApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { filterCategoryData, rolePermissionChecker, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const Category = () => {
    const navigate = useNavigate();

    const [categoryList, setCategoryList] = useState(null);
    const [categoryNameList, setCategoryNameList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [categoryListChanged, setCategoryListChanged] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        // const permissionAllowed = rolePermissionChecker("Category");
        // if (!permissionAllowed) {
        //     toast.error("You don't have permission to access this module");
        //     navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`);
        // }
    });

    const togglePopup = () => {
        setSelectedRow({});
        setIsOpen(!isOpen);
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleDelete = async (id) => {
        const deleteUrl = `${categoryApi.delete}${id}`
        const response = await APIService.delete(deleteUrl)
        console.log("delete response  ", response)
        if (response.status === 204) {
            toast.success("Entry Deleted Successfully");
        } else {
            toast.error("Please Try Again !");
            return
        }
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setCategoryListChanged(!categoryListChanged);
    };

    const getCategory = async () => {
        setIsLoading(true);
        const response = await APIService.get(categoryApi.filter);
        const updatedList = await addSerialNumbersToList(response.categoryList);
        setCategoryList(updatedList);
    };

    const getCategoryFilterData = async () => {
        const response = await APIService.get(categoryApi.filterCategory);
        setCategoryNameList(response.categoryList || []);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        const id = rowData?.id
        if (action === "delete") {
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
            setSelectedRow({});
        } else if (action === "edit") {
            setSelectedRow(rowData);
            setIsOpen(true);
            setSelectedId(id);
        }
    };

    useEffect(() => {
        getCategory();
        getCategoryFilterData();
    }, [categoryListChanged]);

    const columnsArray = [
        ...CATEGORY_HEADER,
        {
            Header: "Status",
            accessor: "status",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="text-[25] flex justify-center">
                    <TrashIcon {...icon} onClick={() => handleActionClick("delete", row.original)} />
                    <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original)} />
                </div>
            ),
        },
    ];

    const onAddCategorySubmit = async (data, action) => {
        let response;
        if (action === "post") {
            response = await APIService.post(categoryApi.post, data);
        } else if (action === "patch") {
            response = await APIService.patch(`${categoryApi.patch}${selectedId}`, data);
        }

        if (response.status === 200 || response.status === 201) {
            toast.success("Category saved successfully");
        } else {
            toast.error("Error while saving category!");
            return;
        }

        setIsOpen(!isOpen);
        setCategoryListChanged(!categoryListChanged);
    };

    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target
        const filteredOrders = filterCategoryData(value, categoryList);

        if (filteredOrders.length === 0) {
            setCategoryListChanged(!categoryListChanged);
        } else {
            setCategoryList(filteredOrders ?? []);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader title="Category" description="Category" showHeader={true} showDatePicker={false} showSearchInput={false} showButton={false} />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        {isOpen && <AddCategory togglePopup={togglePopup} isOpen={isOpen} categoryNameList={categoryNameList} onAddCategorySubmit={onAddCategorySubmit} selectedRow={selectedRow} />}
                        {isOpenDeleteModal && selectedId && <DeleteConfirmationModal handleDelete={handleDelete} isOpen={isOpenDeleteModal} togglePopup={toggleDeletePopup} id={selectedId} />}
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} />
                            </div>
                            <div className="flex">
                                <div className="pr-[2px]">
                                    <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, categoryList, "CategoryList") }} />
                                </div>
                                <div>
                                    <Button text="Add Category" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePopup} />
                                </div>
                            </div>
                        </div>
                        {categoryList && <PaginationTable columnsData={columnsArray} rowData={categoryList} />}
                    </div>
                </>
            )}
        </>
    );
}

export default Category;

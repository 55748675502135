import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Blocks, ColorRing } from "react-loader-spinner";
import { set, cloneDeep, get, omit, find } from "lodash";
import moment from "moment/moment";
import { toast } from "react-toastify";

import Radio from "@/components/Radio";
import Button from "@/components/Button";
import InputField from "@/components/InputField"
import TextAreaField from "@/components/TextAreaField";
import OrderDropdown from "@/components/OrderDropdown";
import MyDatePicker from "@/components/DatePickerComponent";
import ErrorMessage from "@/components/ErrorMessage"
import ContentHeader from "../ContentHeader";

import { useData } from "@/hooks/useData";
import { APIService } from "@/services";
import { enquireQuatationApi, organizationApi, enquireApi, orderApi, getOrderDataApi } from "@/utils/API";
import { scrollToTop } from "@/utils/CustomFunctions";
import { createRequestPayload } from "./functions";
import { 
    INITIAL_DROPDOWN_OPTIONS,
    INITIAL_FORM_VALUE,
    RADIO_OPTIONS_TYRE_TYPE,
    RADIO_OPTIONS_TRAILER_TYPE,
    SIDE_WALL_TYPES,
    REQUIRED_FIELDS,
    ERROR_MESSAGE_VALUES,
    PRODUCT_MODEL
} from "./constants";
import { APP_URLS } from "@/routes";
import { REGEX } from "@/utils/AppConstants";

const EnquireQuotation = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const { data } = useData();

    const enquireId = queryParams.get("enquiryId");
    const quotationId = queryParams.get("quotationId");

    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUE);
    const [dropdownOptions, setDropdownOptions] = useState(INITIAL_DROPDOWN_OPTIONS);
    const [isDropdownOptionsAvailable, setIsDropdownOptionsAvailable] = useState(true);
    const [organizationDetails, setOrganizationDetails] = useState({});
    const [quotationIdNumber, setQuotationIdNumber] = useState("");
    const [orderGenerated, setOrderGenerated] = useState(false);
    const [gstRadio, setGstRadio] = useState("Trailer")
    const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGE_VALUES);
    const sideWallTypeDropDownValue = SIDE_WALL_TYPES;

    const formatDate = (selectedDate) => {
        if (selectedDate === null || selectedDate === "") return null;
        const formattedDateString = moment(selectedDate).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
        return new Date(formattedDateString);
    };

    const getTotalCostOftrailerValue = () => {
        const quantity = get(formValues, "quantity");
        const perTrailerCost = get(formValues, "perTrailerCost");
        const totalCost = Number(quantity) * Number(perTrailerCost);
        return isNaN(totalCost) ? 0 : totalCost;
    };

    const getGSTPercent = (radioBtnVal) => {
        const { gstDetails } = organizationDetails;
        return radioBtnVal === "Trailer" ? gstDetails?.igst : gstDetails?.cgst;
    };
    
    const calculateBaisAmount = (value, radioBtnVal) => {
        const updatedState = cloneDeep(formValues);
        const gstPercent = getGSTPercent(radioBtnVal);
        
        const basicAmount = ((Number(value) / (Number(gstPercent) + 100)) * 100).toFixed(3);
        const gstAmount = (Number(basicAmount) * (Number(gstPercent)) / 100).toFixed(3);
        set(updatedState, "basicCost", isNaN(basicAmount) ? 0 : basicAmount);
        set(updatedState, "gstAmount", isNaN(gstAmount) ? 0 : gstAmount);
        set(updatedState, "gstPercentage", gstPercent);
        setFormValues(updatedState);
    };

    const handleDateChange = (name, date) => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, name, date);
        console.log(updatedState);
        setFormValues(updatedState);
    };

    const handleRadioOptionChange = event => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, event.target.name, event.target.value);
        console.log(updatedState);
        setFormValues(updatedState);
    };

    const handleOptionSelect = (option, id) => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, id, { ...option, selected: true });
        console.log(updatedState);
        setFormValues(updatedState);
    };

    const handleRadioChange = (event) => {
        setGstRadio(event.target.value);
        calculateBaisAmount(formValues?.perTrailerCost, event.target.value);
    };

    const handleOnBlur = (e) => {
        const { name, value } = e.target;
        if (name === "perTrailerCost") {
            calculateBaisAmount(value, gstRadio);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(formValues);
        set(updatedState, name, value);
        setFormValues(updatedState);
    };

    const handleSubmit = async (payloadName) => {
        setIsPending(true);

        let newErrorMessages = {};
        REQUIRED_FIELDS.forEach((field) => {
            if (!get(formValues, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "quantity" || field === "perTrailerCost" || field === "trailerLength") {
                    if (field === "quantity" || field === "perTrailerCost") {
                        if (!get(formValues, field).toString().match(REGEX.POSITIVE_GRT_ZERO)) {
                            newErrorMessages[field] = "Please enter valid input";
                        }
                    }
                    if (field === "trailerLength") {
                        if (!get(formValues, field).toString().match(REGEX.FRACTION)) {
                            newErrorMessages[field] = "Please enter valid input";
                        }
                    }
                };

                if (field === "contactMedium.phoneNumber" && !get(formValues, field).toLowerCase().match(REGEX.PHONE)) {
                    newErrorMessages[field] = "Please enter valid phone number";
                };
            }
        });
        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            setIsPending(false);
            scrollToTop();
            toast.error("Please fill mandatory fields");
            return;
        };

        const payload = createRequestPayload(formValues, payloadName);
        //console.log(payload);
        //return;

        if (payloadName === "quotation") {
            saveQuotation(payload); // create or patch quotation
        } else {
            saveOrder(payload); // generate work order
        }
    };

    const saveQuotation = async (payload) => {
        let response;
        if (quotationId !== "" && quotationId !== "0") {
            response = await APIService.patch(`${enquireQuatationApi.patch}${quotationId}`, payload);
        } else {
            response = await APIService.post(enquireQuatationApi.post, payload);
        }

        setIsPending(false);
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Quotation saved successfully");
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_LIST}`);
        } else {
            scrollToTop();
            const { errorCode } = response?.data;
            if (errorCode === "missing_mandate_field") {
                toast.error("Please fill mandatory fields");
            } else {
                toast.error("Error while saving the quotation!!");
            }
        }
    };

    const saveOrder = async (payload) => {
        let response = await APIService.post(orderApi.post, payload);

        setIsPending(false);
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Work order saved successfully");
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALES_WO_LIST}`);
        } else {
            console.log(response);
            const { error } = response?.data;
            toast.error(error);
        }
    };

    // when no quotation created before
    const getInitialOrgEnquiryData = async () => {
        // const orgResponse = await APIService.get(`${organizationApi.get}`);
        const orgResponse = data.organization;
        const enquiryResponse = await APIService.get(`${enquireApi.get}${enquireId}`);

        if (orgResponse?.organizationList[0] && enquiryResponse?.enquiry) {
            const { gstDetails } = orgResponse?.organizationList[0];
            const {
                firstName,
                lastName,
                transporterName,
                contactMedium,
                quantity,
                location,
                salesPersonName,
                typeOfTrailer,
                length,
                width,
                axleType,
                height,
            } = enquiryResponse?.enquiry;

            const { trailerType, trailerWidth, trailerHeight, trailerAxleType } = data.orderForm;
            let selectedTrailer = find(trailerType, ["productName", typeOfTrailer]);
            if (selectedTrailer) {
                set(selectedTrailer, 'selected', true);
            };
            let selectedTrailerWidth = find(trailerWidth, ["productName", width]);
            let selectedTrailerHeight = find(trailerHeight, ["productName", height]);
            let selectedTrailerAxleType = find(trailerAxleType, ["productName", axleType]);
            //let selectedTrailerLength = find(trailerLength, ["productName", length]);

            const defaultValues = {
                gstPercentage: gstDetails.igst,
                customerName: transporterName,
                customerAddress: {
                    ...formValues.customerAddress,
                    city: `${firstName} ${lastName}`,
                    fullAddress: location
                },
                inquiryAttended: salesPersonName,
                contactMedium,
                quantity,
                enquiryId: enquireId,
                trailerType: selectedTrailer || {},
                trailerWidth: selectedTrailerWidth || {},
                trailerHeight: selectedTrailerHeight || {},
                trailerAxleType: selectedTrailerAxleType || {},
                trailerLength: length
                //trailerLength: selectedTrailerLength || {},
            };

            let quotation = {
                ...formValues,
                ...defaultValues
            };

            setFormValues(quotation);
            setOrganizationDetails(orgResponse?.organizationList[0]);
        };
    };

    // when quotation created
    const getQuotation = async () => {
        // const orgResponse = await APIService.get(`${organizationApi.get}`);
        const orgResponse = data.organization;
        const response = await APIService.get(`${enquireQuatationApi.get}/${quotationId}`);
        if (response?.quotation) {
            patchForm(response?.quotation, orgResponse?.organizationList[0]);
        }
    };

    const patchForm = (quotation, organization) => {
        const gstPercent = quotation?.gstPercentage;
        const igstValue = organization?.gstDetails?.igst;

        const updatedValues = omit(quotation, ["lastUpdated", "lastUpdatedBy", "orderGenerateFlag", "organizationDetails", "suspension", "warrenty"]);
        const { trailerWidth, trailerHeight, trailerAxleType } = data.orderForm;
        let selectedTrailerWidth = find(trailerWidth, ["productName", quotation?.width]);
        let selectedTrailerAxleType = find(trailerAxleType, ["productName", quotation?.axleType]);
        let selectedTrailerHeight = find(trailerHeight, ["productName", quotation?.height]);
        //let selectedTrailerLength = find(trailerLength, ["productName", quotation?.length]);
        const patchValues = {
            axlesMake: {
                ...quotation.axlesMake.make[0]
            },
            axlesType: {
                ...quotation.axlesMake.type[0]
            },
            tyre: {
                ...quotation.tyre.make[0],
            },
            // radialNylon: quotation.tyre.radial ? "Radial" : "Nylon",  Not Required; Commented 08/11/2024
            radialNylon: "Radial", // Sending Radial Default
            suspensionMake: {
                ...quotation.suspension.make[0]
            },
            suspensionType: {
                ...quotation.suspension.type[0]
            },
            // suspensionCombination: {
            //     ...quotation.suspension.combination[0]    Not Required; Commented 08/11/2024
            // }, 
            suspensionCombination: { ...PRODUCT_MODEL }, // Sending Blank
            trailerLength: quotation?.length,
            trailerWidth: selectedTrailerWidth || {},
            trailerAxleType: selectedTrailerAxleType || {},
            trailerHeight: selectedTrailerHeight || {}
            //trailerLength: selectedTrailerLength || {},
        };

        setOrderGenerated(quotation.orderGenerateFlag || false);
        setGstRadio((gstPercent === igstValue) ? "Trailer" : "Tipper");
        setQuotationIdNumber(quotation?.quotationId);
        setOrganizationDetails(organization);
        setFormValues({ ...updatedValues, ...patchValues });
    };

    const getDropdownOptionsList = async () => {
        // const response = await APIService.get(getOrderDataApi.get);
        const options = data.orderForm;
        setDropdownOptions((prevoptions) => ({
            ...prevoptions,
            ...options
        }));
        setIsDropdownOptionsAvailable(true);
    };

    useEffect(() => {
        if (data.orderForm) {
            getDropdownOptionsList();
            if (quotationId !== "0") {
                getQuotation();  //when quotation created
            } else if (quotationId === "0") {
                getInitialOrgEnquiryData(); //when no quotation created before
            }
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader title="Quotation" description={`Quotation No: ${quotationIdNumber || "N/A"}`} showHeader={true} showDatePicker={false} showSearchInput={false} showButton={false} />
                    {isDropdownOptionsAvailable && <div className="m-4 flex flex-col rounded h-auto bg-white">
                        <div className="flex p-2.5 justify-between items-center">
                            {/* <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} /> */}
                        </div>

                        { orderGenerated && <span className="text-red-500 text-[12px]">Work Order Created for Quotation: <strong>{quotationIdNumber}</strong></span>}
                        <fieldset disabled={orderGenerated ? `disabled`: ``}>
                            <div className="grid grid-cols-1 px-3.5">
                                <Radio
                                    name="gstRadio"
                                    value={gstRadio}
                                    onChange={handleRadioChange}
                                    options={RADIO_OPTIONS_TRAILER_TYPE}
                                    id="gstRadio"
                                />
                            </div>
                            <div className="grid grid-cols-6 gap-4 mb-4 p-3.5">
                                <div>
                                    <InputField
                                        name="basicCost"
                                        label="Basic Amount"
                                        value={get(formValues, "basicCost")}
                                        onChange={handleInputChange}
                                        disabled={true}
                                        required={true}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        name="gstPercentage"
                                        label="GST %"
                                        value={get(formValues, "gstPercentage")}
                                        onChange={handleInputChange}
                                        disabled={true}
                                        required={true}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        name="gstAmount"
                                        label="GST Amount"
                                        value={get(formValues, "gstAmount")}
                                        onChange={handleInputChange}
                                        disabled={true}
                                        required={true}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        name="perTrailerCost"
                                        label="Per Trailer Cost"
                                        placeholder="Enter per trailer cost"
                                        value={get(formValues, "perTrailerCost")}
                                        onChange={handleInputChange}
                                        onBlur={handleOnBlur}
                                        required={true}
                                    />
                                    {errorMessages.perTrailerCost && <ErrorMessage error={errorMessages.perTrailerCost} />}
                                </div>
                                <div>
                                    <InputField
                                        name="quantity"
                                        label="Quantity"
                                        placeholder=""
                                        value={get(formValues, "quantity")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.quantity && <ErrorMessage error={errorMessages.quantity} />}
                                </div>
                                <div>
                                    <InputField
                                        name="totalCostOfTrailer"
                                        label="Total cost of trailer"
                                        value={getTotalCostOftrailerValue(formValues)}
                                        onChange={handleInputChange}
                                        disabled={true}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-6 gap-4 p-3.5">
                                <div>
                                    <InputField
                                        name="customerName"
                                        label="Customer Name"
                                        placeholder="Enter customer name"
                                        value={get(formValues, "customerName")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.customerName && <ErrorMessage />}
                                </div>
                                <div className="col-span-2">
                                    <InputField
                                        name="customerAddress.fullAddress"
                                        label="Address"
                                        placeholder="Enter customer address"
                                        value={get(formValues, "customerAddress.fullAddress")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {get(errorMessages, "customerAddress.fullAddress") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="contactMedium.phoneNumber"
                                        label="Contact"
                                        placeholder="Enter customer contact"
                                        value={get(formValues, "contactMedium.phoneNumber")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {get(errorMessages, "contactMedium.phoneNumber") && <ErrorMessage error={get(errorMessages, "contactMedium.phoneNumber")} />}
                                </div>
                                <div className="mb-4 col-span-1">
                                    <InputField
                                        name="inquiryAttended"
                                        label="Enquiry Attended by"
                                        placeholder="Enter enquiry attended by"
                                        value={get(formValues, "inquiryAttended")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.inquiryAttended && <ErrorMessage />}
                                </div>
                                <div>
                                    <MyDatePicker label="Date" name="inquiryAttendedDate" selectedDate={formatDate(get(formValues, "inquiryAttendedDate"))} handleDateChange={handleDateChange} required={true} />
                                    {errorMessages.inquiryAttendedDate && <ErrorMessage />}
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-6 gap-4 p-3.5">
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.trailerType ?? []}
                                        selectedOption={get(formValues, "trailerType")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Trailer Type"
                                        id="trailerType"
                                        required={true}
                                    />
                                    {get(errorMessages, "trailerType.productName") && <ErrorMessage />}
                                </div>
                                {/* <div>
                                    <OrderDropdown
                                        options={sideWallTypeDropDownValue ?? []}
                                        selectedOption={get(formValues, "sideWallType")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Side Wall Type"
                                        id="sideWallType"
                                        required={true}
                                    />
                                    {get(errorMessages, "sideWallType.productName") && <ErrorMessage />}
                                </div> */}
                                <div className="col-span-2">
                                    <InputField
                                        name="trailerDescription"
                                        label="Trailer Description"
                                        value={get(formValues, "trailerDescription")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        name="application"
                                        label="Application"
                                        value={get(formValues, "application")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.truckModel ?? []}
                                        selectedOption={get(formValues, "truckModel")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Prime Mover Model"
                                        id="truckModel"
                                        required={true}
                                    />
                                    {get(errorMessages, "truckModel.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.mainSpineIBeam ?? []}
                                        selectedOption={get(formValues, "mainSpineIBeam")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Main Spine I-Beam"
                                        id="mainSpineIBeam"
                                        required={true}
                                    />
                                    {get(errorMessages, "mainSpineIBeam.productName") && <ErrorMessage />}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4 p-3.5">
                                <span className="text-xl font-small uppercase text-left">TRAILER DIMENSIONS</span>
                                <div className="grid grid-cols-6 gap-4">
                                    <div>
                                        <InputField
                                            name="trailerLength"
                                            label="Length (In Feet)"
                                            placeholder=""
                                            value={get(formValues, "trailerLength")}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                        {errorMessages.trailerLength && <ErrorMessage error={errorMessages.trailerLength} />}
                                    </div>
                                    <div>
                                        <OrderDropdown
                                            options={dropdownOptions?.trailerWidth ?? []}
                                            selectedOption={get(formValues, "trailerWidth")}
                                            onOptionSelect={handleOptionSelect}
                                            label="Width"
                                            id="trailerWidth"
                                            required={true}
                                        />
                                        {get(errorMessages, "trailerWidth.productName") && <ErrorMessage />}
                                    </div>
                                    <div>
                                        <OrderDropdown
                                            options={dropdownOptions?.trailerHeight ?? []}
                                            selectedOption={get(formValues, "trailerHeight")}
                                            onOptionSelect={handleOptionSelect}
                                            label="Height"
                                            id="trailerHeight"
                                            required={true}
                                        />
                                        {get(errorMessages, "trailerHeight.productName") && <ErrorMessage />}
                                    </div>
                                    <div>
                                        <OrderDropdown
                                            options={dropdownOptions?.trailerAxleType ?? []}
                                            selectedOption={get(formValues, "trailerAxleType")}
                                            onOptionSelect={handleOptionSelect}
                                            label="Vehicle Type"
                                            id="trailerAxleType"
                                            required={true}
                                        />
                                        {get(errorMessages, "trailerAxleType.productName") && <ErrorMessage />}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 p-3.5">
                                <div className="grid grid-cols-1 gap-3">
                                    <span className="text-xl font-small uppercase text-left">AXLES</span>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.axlesMake ?? []}
                                                selectedOption={get(formValues, "axlesMake")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Make"
                                                id="axlesMake"
                                                required={true}
                                            />
                                            {get(errorMessages, "axlesMake.productName") && <ErrorMessage />}
                                        </div>
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.axlesType ?? []}
                                                selectedOption={get(formValues, "axlesType")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Type"
                                                id="axlesType"
                                                required={true}
                                            />
                                            {get(errorMessages, "axlesType.productName") && <ErrorMessage />}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-3">
                                    <span className="text-xl font-small uppercase text-left">SUSPENSION</span>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.suspensionMake ?? []}
                                                selectedOption={get(formValues, "suspensionMake")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Make"
                                                id="suspensionMake"
                                                required={true}
                                            />
                                            {get(errorMessages, "suspensionMake.productName") && <ErrorMessage />}
                                        </div>
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.suspensionType ?? []}
                                                selectedOption={get(formValues, "suspensionType")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Type"
                                                id="suspensionType"
                                                required={true}
                                            />
                                            {get(errorMessages, "suspensionType.productName") && <ErrorMessage />}
                                        </div>
                                        {/* <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.suspensionCombination ?? []}
                                                selectedOption={get(formValues, "suspensionCombination")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Combination"
                                                id="suspensionCombination"
                                                required={true}
                                            />
                                            {get(errorMessages, "suspensionCombination.productName") && <ErrorMessage />}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 p-3.5">
                                <div className="grid grid-cols-1 gap-3">
                                    <div className="grid grid-cols-3 gap-3 mt-8">
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.tyreMake ?? []}
                                                selectedOption={get(formValues, "tyre")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Tyre Make"
                                                id="tyre"
                                                required={true}
                                            />
                                            {get(errorMessages, "tyre.productName") && <ErrorMessage />}
                                        </div>
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.landingLeg ?? []}
                                                selectedOption={get(formValues, "landingLag")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Landing Leg"
                                                id="landingLag"
                                                required={true}
                                            />
                                            {get(errorMessages, "landingLag.productName") && <ErrorMessage />}
                                        </div>
                                        {/* <div>
                                            <Radio
                                                label="Tyre Type"
                                                name="radialNylon"
                                                value={get(formValues, "radialNylon")}
                                                onChange={handleRadioOptionChange}
                                                options={RADIO_OPTIONS_TYRE_TYPE}
                                                id="radialNylon"
                                                required={true}
                                            />
                                            {get(errorMessages, "radialNylon") && <ErrorMessage />}
                                        </div> */}
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-3">
                                    <span className="text-xl font-small uppercase text-left">BREAKING SYSTEM</span>
                                    <div className="grid grid-cols-3 gap-3">
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.brakeKit ?? []}
                                                selectedOption={get(formValues, "brakeKit")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Brake Kit Make"
                                                id="brakeKit"
                                                required={true}
                                            />
                                            {get(errorMessages, "brakeKit.productName") && <ErrorMessage />}
                                        </div>
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.brakeChambers ?? []}
                                                selectedOption={get(formValues, "brakeChamber")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Brake Chambers"
                                                id="brakeChamber"
                                                required={true}
                                            />
                                            {get(errorMessages, "brakeChamber.productName") && <ErrorMessage />}
                                        </div>
                                        <div>
                                            <OrderDropdown
                                                options={dropdownOptions?.actuators ?? []}
                                                selectedOption={get(formValues, "actuators")}
                                                onOptionSelect={handleOptionSelect}
                                                label="Actuators"
                                                id="actuators"
                                                required={true}
                                            />
                                            {get(errorMessages, "actuators.productName") && <ErrorMessage />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 gap-4 p-3.5 mb-4">
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.floorSheet ?? []}
                                        selectedOption={get(formValues, "floorSheet")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Platform Floor"
                                        id="floorSheet"
                                        required={true}
                                    />
                                    {get(errorMessages, "floorSheet.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.paddingPlate ?? []}
                                        selectedOption={get(formValues, "paddingPlate")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Padding Plate"
                                        id="paddingPlate"
                                        required={true}
                                    />
                                    {get(errorMessages, "paddingPlate.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.coilLashing ?? []}
                                        selectedOption={get(formValues, "coilLashing")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Coil Mounting"
                                        id="coilLashing"
                                        required={true}
                                    />
                                    {get(errorMessages, "coilLashing.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.toolBox ?? []}
                                        selectedOption={get(formValues, "toolBox")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Tool Box"
                                        id="toolBox"
                                        required={true}
                                    />
                                    {get(errorMessages, "toolBox.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.crossMembers ?? []}
                                        selectedOption={get(formValues, "crossMember")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Cross Members"
                                        id="crossMember"
                                        required={true}
                                    />
                                    {get(errorMessages, "crossMember.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="fishPlate"
                                        label="Fish Plate"
                                        placeholder=""
                                        value={get(formValues, "fishPlate")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.fishPlate && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.sideRave ?? []}
                                        selectedOption={get(formValues, "sideRave")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Side Rave"
                                        id="sideRave"
                                        required={true}
                                    />
                                    {get(errorMessages, "sideRave.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.taperSupport ?? []}
                                        selectedOption={get(formValues, "taperSupport")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Taper - Support"
                                        id="taperSupport"
                                        required={true}
                                    />
                                    {get(errorMessages, "taperSupport.productName") && <ErrorMessage />}
                                </div>
                            </div>
                            <hr />
                            <div className="grid grid-cols-6 gap-4 p-3.5">
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.wheelRim ?? []}
                                        selectedOption={get(formValues, "wheelRim")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Wheel Rim"
                                        id="wheelRim"
                                        required={true}
                                    />
                                    {get(errorMessages, "wheelRim.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.kingpin ?? []}
                                        selectedOption={get(formValues, "kingpin")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Kingpin"
                                        id="kingpin"
                                        required={true}
                                    />
                                    {get(errorMessages, "kingpin.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="electrical"
                                        label="Electrical"
                                        placeholder=""
                                        value={get(formValues, "electrical")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.electrical && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.twistLock ?? []}
                                        selectedOption={get(formValues, "twistLock")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Twist Lock"
                                        id="twistLock"
                                        required={true}
                                    />
                                    {get(errorMessages, "twistLock.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.tieDownHook ?? []}
                                        selectedOption={get(formValues, "tieDownHook")}
                                        onOptionSelect={handleOptionSelect}
                                        label="J' Hook"
                                        id="tieDownHook"
                                        required={true}
                                    />
                                    {get(errorMessages, "tieDownHook.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.khutlaPocket ?? []}
                                        selectedOption={get(formValues, "khutlaPocket")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Khutla Pocket (Qty)"
                                        id="khutlaPocket"
                                        required={true}
                                    />
                                    {get(errorMessages, "khutlaPocket.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.khutlaPillarDetails ?? []}
                                        selectedOption={get(formValues, "khutlaPillarDetails")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Khutla Pillar Details"
                                        id="khutlaPillarDetails"
                                        required={true}
                                    />
                                    {get(errorMessages, "khutlaPillarDetails.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="supd"
                                        label="Other Accessories"
                                        value={get(formValues, "supd")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.supd && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="spareWheelCarrierBkt"
                                        label="Spare Wheel Bracket"
                                        value={get(formValues, "spareWheelCarrierBkt")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.spareWheelCarrierBkt && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.reflectiveTape ?? []}
                                        selectedOption={get(formValues, "reflectiveTape")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Reflective Tape"
                                        id="reflectiveTape"
                                        required={true}
                                    />
                                    {get(errorMessages, "reflectiveTape.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="note"
                                        label="Delivery Period"
                                        value={get(formValues, "note")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.note && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.colourShade ?? []}
                                        selectedOption={get(formValues, "colourShade")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Colour Shade"
                                        id="colourShade"
                                        required={true}
                                    />
                                    {get(errorMessages, "colourShade.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="fabrication"
                                        label="Fabrication - Welding"
                                        value={get(formValues, "fabrication")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.fabrication && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.lashingPlate ?? []}
                                        selectedOption={get(formValues, "lashingPate")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Lashing Plate"
                                        id="lashingPate"
                                        required={true}
                                    />
                                    {get(errorMessages, "lashingPate.productName") && <ErrorMessage />}
                                </div>
                                <div>
                                    <InputField
                                        name="surfaceTreatment"
                                        label="Surface Treatment"
                                        value={get(formValues, "surfaceTreatment")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.surfaceTreatment && <ErrorMessage />}
                                </div>
                                <div>
                                    <OrderDropdown
                                        options={dropdownOptions?.tailDoorWindow ?? []}
                                        selectedOption={get(formValues, "tailDoorWindow")}
                                        onOptionSelect={handleOptionSelect}
                                        label="Tail Door - Window"
                                        id="tailDoorWindow"
                                        required={true}
                                    />
                                    {get(errorMessages, "tailDoorWindow.productName") && <ErrorMessage />}
                                </div>
                                <div className="col-span-2">
                                    <TextAreaField
                                        name="additionalInformation"
                                        label="Additional Information"
                                        placeholder="Enter your description here"
                                        value={get(formValues, "additionalInformation")}
                                        onChange={handleInputChange}
                                        required={true}
                                    />
                                    {errorMessages.additionalInformation && <ErrorMessage />}
                                </div>
                            </div>
                        </fieldset>
                        {(!isPending && !orderGenerated) &&
                            <div className="flex p-2.5 gap-2">
                                <Button text={`${quotationIdNumber ? "Update": "Create"} Quotation`} textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit("quotation")} />
                                { quotationIdNumber && <Button text="Generate Work Order" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} /> }
                                <Button text="Cancel" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                            </div>
                        }
                        {isPending && <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                        />}
                    </div>}
                </>
            )}
        </>
    );
};

export default EnquireQuotation;

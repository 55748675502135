import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate } from 'react-router-dom';
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "../ContentHeader";
import UpdateWorkOrderStatusPopup from "../WorkOrder/UpdateWorkOrderStatusPopup";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { orderApi } from "@/utils/API";
import { filterWorkOrder, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { WORK_ORDER_LIST_HEADER } from "../WorkOrder/Headers";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const ProductionWorkOrderList = () => {
    const navigate = useNavigate();
    const { user: auth } = useAuth();

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [workOrderList, setWorkOrderList] = useState(null);
    const [originalWorkOrderList, setOriginalWorkOrderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [orderPlaceListChanged, setOrderPlaceListChanged] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);

    const toggleStatusPopup = () => {
        setSelectedRow({});
        setIsStatusPopupOpen(!isStatusPopupOpen);
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getData = async () => {
        setIsLoading(true);

        let url = isAdmin ? orderApi.getParentOrders: orderApi.getParentOrdersByUsername(auth.username); // get base order only
        const response = await APIService.get(url);
        const updatedList = await addSerialNumbersToList(response.orderList);
        setWorkOrderList(updatedList);
        setOriginalWorkOrderList(updatedList);
        setIsLoading(false);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${orderApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setOrderPlaceListChanged(!orderPlaceListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "viewsuborder") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.PROD_DEPT_WO}?workOrderId=${rowData.id}&quotationId=${rowData.quotationId}`);
        } else if (action === "view") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_WO}?quotationId=${rowData.quotationId}&workOrderId=${rowData.id}&orderType=0&mode=view`);
        } else if (action === "generateMaterialRequest") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.RAISE_MAT_REQ}?workOrderId=${rowData.id}`);
        } else if (action === "viewMaterialRequest") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.MAT_REQ_LIST}?workOrderId=${rowData.orderId}`);
        } else if (action === "updateWOStatus") {
            setSelectedRow(rowData);
            setIsStatusPopupOpen(true);
        } else if (action === "productionUpdates") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.WO_UPDATES}?workOrderId=${rowData.id}&labelId=${rowData.orderId}`);
        } else if (action === "generateChassisNo") {
            onGenerateChassisNo(rowData.id);
        }
    };

    const onGenerateChassisNo = async (orderId) => {
        setIsLoading(true);
        let response = await APIService.patch(`${orderApi.generateChassis}${orderId}`, {});
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Chassis No Generated Successfully");
            setOrderPlaceListChanged(!orderPlaceListChanged);
        } else {
            console.log(response);
            const { error } = response?.data;
            toast.error(error);
        }
        setIsLoading(false);
    };

    const onUpdateStatusSubmit = async (payload) => {
        let response = await APIService.patch(`${orderApi.patch}${selectedRow.id}`, payload);
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Status Updated Successfully");
        } else {
            console.log(response);
            const { error } = response?.data;
            toast.error(error);
        }

        setOrderPlaceListChanged(!orderPlaceListChanged);
        setSelectedRow({});
        setIsStatusPopupOpen(false);
    };

    // function to filter orders based on search input
    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target
        const filteredOrders = filterWorkOrder(value, originalWorkOrderList);
        setWorkOrderList(filteredOrders.length === 0 ? originalWorkOrderList : filteredOrders); 
    };

    const getSelectedDates = (data) => {
        const startDate = data[0];
        const endDate = data[1];

        if (startDate !== null && endDate !== null) {
            const startDateToFilter = new Date(startDate);
            const endDateToFilter = new Date(endDate);
            startDateToFilter.setHours(0, 0, 0, 0);
            endDateToFilter.setHours(0, 0, 0, 0);

            const filteredOrderList = originalWorkOrderList.filter((order) => {
                const inquiryAttendedDate = new Date(order.inquiryAttendedDate);
                inquiryAttendedDate.setHours(0, 0, 0, 0);
                return (
                    inquiryAttendedDate >= startDateToFilter &&
                    inquiryAttendedDate <= endDateToFilter
                );
            });
            setWorkOrderList(filteredOrderList.length > 0 ?  filteredOrderList: originalWorkOrderList);
        } else {
            setWorkOrderList(originalWorkOrderList);
        }
    };

    useEffect(() => {
        getData();
    }, [orderPlaceListChanged]);

    const columnsArray = [
        ...WORK_ORDER_LIST_HEADER,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => {
                const { status, quantity } = row.original;
                return (
                    <>
                        <div className="text-[25] flex justify-center text-[#39ACE4]">
                            <EyeIcon title="View Order Details" {...icon} onClick={() => handleActionClick("view", row.original) } />
                            { (status.toLowerCase() === "approved" && quantity > 1) && <BarsArrowUpIcon {...icon} title="View Sub Orders" onClick={() => handleActionClick("viewsuborder", row.original)} /> }
                        </div>
                        {
                            (status.toLowerCase() !== "initial" && quantity === 1) && <>
                                <div className="text-[25] flex flex-col justify-center text-[#39ACE4] mt-4">
                                    <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("generateMaterialRequest", row.original)}>Raise MR</span>
                                    <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("viewMaterialRequest", row.original)}>View MR</span>
                                    <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("updateWOStatus", row.original)}>Change Status</span>
                                    <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("productionUpdates", row.original)}>Feed Updates</span>
                                    { (row.original.status === "Paint Shop" && !row.original.chassisNumber) && <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("generateChassisNo", row.original)}>Generate Chassis No.</span> }
                                </div>
                            </>
                        }
                    </>
                )
            },
        },
    ];

    return (
        <>
            <ContentHeader
                title="Work Order"
                description="Production Department / Work Order"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
                filterDataFunction={filterDataFunction}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                {isStatusPopupOpen && <UpdateWorkOrderStatusPopup togglePopup={toggleStatusPopup} isOpen={isStatusPopupOpen} onUpdateStatus={onUpdateStatusSubmit} order={selectedRow} isAdmin={isAdmin} />}

                {/* <span className="text-xl font-small pt-3.5 pl-3.5">Work Order List</span> */}

                <div className="flex justify-between mr-5 mt-4 ml-3">
                    {!isEmpty(workOrderList) && <>
                        <div className="flex space-x-3">
                            <TableCustomFilter filterDataFunction={filterDataFunction} getSelectedDates={getSelectedDates} />
                        </div>
                        <div>
                                <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, workOrderList, "WorkOrderList") }} />
                        </div>
                    </>}
                </div>

                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    workOrderList && <PaginationTable columnsData={columnsArray} rowData={workOrderList} />
                )}
            </div>
        </>
    )
}

export default ProductionWorkOrderList;

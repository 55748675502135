import React, { useEffect, useState, useRef } from "react";
import { Blocks } from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { APIService } from "@/services";
import { accountApi } from "@/utils/API";

import Button from "@/components/Button";
import ContentHeader from "../../ContentHeader";
import PrintReceipt from "./PrintReceipt";

const headerButton = { showBackBtn: true };

const WorkOrderReceipt = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const componentRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [receipt, setReceipt] = useState({});
    const [organization, setOrganization] = useState({});

    const id = queryParams.get("id");
    const receiptId = queryParams.get("receiptId");
        
    const takePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = () => {
        setTimeout(() => {
            takePrint();
        }, 100);
    };

    const getReceiptData = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${accountApi.get}/${id}`);
        if (!isEmpty(response.status) && id && receiptId) {
            let data = response.status;
            data.receiptInfo = data.receiptInfo.find(item => item.receiptNumber === receiptId);
            setReceipt(data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getReceiptData();
    }, []);

    return (
        <>
            {isLoading ? (
                <div className='flex items-center justify-center'>
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
            </div>
            ) : (
                <>
                    <ContentHeader
                        title="Account Department"
                        description="Work Order Receipt"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={true}
                        btnToShow={headerButton}
                    />
                    {(!isEmpty(receipt)) &&  <>
                        <PrintReceipt
                            ref={componentRef}
                            organization={organization}
                            data={receipt}
                        />

                        <div className="flex justify-center">
                            <div className="m-2">
                                <Button text="Back" textColor="white" bgColor="[#1A91D1]" onClick={() => navigate(-1)} />
                            </div>
                            <div className="m-2">
                                <Button text="Print Receipt" textColor="white" bgColor="[#1A91D1]" onClick={() => handlePrint()} />
                            </div>
                        </div>
                    </>}
                </>
            )}
        </>
    );
};

export default WorkOrderReceipt;

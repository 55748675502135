import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { Blocks } from "react-loader-spinner";
import { DocumentIcon, PencilIcon  } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "@/pages/ContentHeader";
import AddEnquiryPopup from "./AddEnquiryPopup";
import { ENQUIRY_LIST_COLUMN } from "./EnquiryListColumn";

import { usePermission } from "@/hooks/useRolePermission";
import { useAuth } from "@/hooks/useAuth";
import { useData } from "@/hooks/useData";
import { APIService } from "@/services";
import { enquireApi, productApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { rolePermissionChecker, filterEnquiry, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const EnquiryList = () => {
    const navigate = useNavigate();
    const { user: auth } = useAuth();
    const { data } = useData();
    const { permissions } = usePermission();

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [isLoading, setIsLoading] = useState(false);
    const [enquiryList, setEnquiryList] = useState([]);
    const [originalEnquiryList, setOriginalEnquiryList] = useState([]);
    const [selectedId, setSelectedId] = useState("");
    const [selectedRow, setSelectedRow] = useState({});

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [enquiryListChanged, setEnquiryListChanged] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({
        trailerType: [],
    });

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const togglePopup = () => {
        setSelectedRow({})
        setIsOpen(!isOpen);
    };

    /** fetch enquiry list */
    const getData = async () => {
        setIsLoading(true);
        let url = isAdmin ? enquireApi.filter : enquireApi.getEnquiryByUsername(auth.username);
        const response = await APIService.get(url);
        const updatedList = await addSerialNumbersToList(response.enquiryList);
        setEnquiryList(updatedList);
        setOriginalEnquiryList(updatedList);
        setIsLoading(false);
    };

    const getDropDownData = async () => {
        // const response = await APIService.get(`${productApi.filterByTrailerType}`);
        // setDropdownOptions({ trailerType: response.productList });
        setDropdownOptions((prevoptions) => ({
            ...prevoptions,
            ...data.orderForm
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${enquireApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);

        if (response.status === 204) {
            toast.success("Enquiry deleted successfully");
        } else {
            toast.error("Please try again!");
            return;
        }

        setIsOpenDeleteModal(!isOpenDeleteModal);
        setEnquiryListChanged(!enquiryListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "quote") {
            const id = rowData?.id
            const isQuotationExist = !isEmpty(rowData?.quotationList) ? true : false;
            const quotationId = rowData?.quotationId || 0;

            setSelectedId(id);
            let url = APP_URLS.APP_ROOT;
            if (isQuotationExist) {
                const queryParams = `?enquiryId=${id}&quotationId=${quotationId}&quotationList=${isQuotationExist}`;
                url += `/${APP_URLS.SALES_ENQ_QUO_LIST}` + queryParams;
            } else {
                const queryParams = `?enquiryId=${id}&quotationId=${quotationId}&quotationList=${isQuotationExist}`;
                url += `/${APP_URLS.SALES_ENQ_QUO}` + queryParams;
            }

            navigate(url);
            return;
        } else if (action === "edit") {
            setSelectedRow(rowData);
            setIsOpen(true);
        }
    };
    
    const onAddEnquireSubmit = async (data, action) => {
        const id = selectedRow?.id;
        let response;
        if (action === "create") {
            response = await APIService.post(enquireApi.post, data);
        } else {
            response = await APIService.patch(`${enquireApi.patch}${id}`, data);
        }
        
        if (response?.message) {
            toast.error(response?.message);
        }
        setIsOpen(false);
        getData();
    };
    
    const columnsArray = [
        ...ENQUIRY_LIST_COLUMN,
        {
            Header: "Action",
            accessor: "action",
            width: 2,
            Cell: ({ value, row }) => {
                return (
                    <div className=" flex justify-center">
                        <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original)} title="Edit Enquiry" />
                        <DocumentIcon {...icon} onClick={() => handleActionClick("quote", row.original) } title="Create/View Quote" />
                    </div>
                );
            }
        }
    ];

    // function to filter orders based on search input
    const filterDataFunction = (searchInput) => {
        const { value } = searchInput?.target
        const filteredEnquiryList = filterEnquiry(value, enquiryList);
        if (filteredEnquiryList.length === 0) {
            setEnquiryListChanged(!enquiryListChanged);
        } else {
            setEnquiryList(filteredEnquiryList ?? []);
        }
    };

    const getSelectedDates = (data) => {
        const startDate = data[0];
        const endDate = data[1];

        if (startDate !== null && endDate !== null) {
            const startDateToFilter = new Date(startDate);
            startDateToFilter.setHours(0, 0, 0, 0);
            const endDateToFilter = new Date(endDate);
            endDateToFilter.setHours(0, 0, 0, 0);

            const filteredEnquiryList = enquiryList.filter((order) => {
                const inquiryAttendedDate = new Date(order.createdOn);
                inquiryAttendedDate.setHours(0, 0, 0, 0);
                return (
                    inquiryAttendedDate >= startDateToFilter &&
                    inquiryAttendedDate <= endDateToFilter
                );
            });
            setEnquiryList(filteredEnquiryList ?? []);
        } else {
            setEnquiryList(originalEnquiryList);
        }
    };

    useEffect(() => {
        const permissionAllowed = rolePermissionChecker("Sales Department", permissions) && rolePermissionChecker("Sales Enquiry", permissions);
        if (!permissionAllowed) {
            toast.error("You don't have permission to access this module");
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`);
        };
    }, []);

    useEffect(() => {
        getData();
    }, [enquiryListChanged]);

    useEffect(() => {
        if (data.orderForm) {
            getDropDownData();
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Enquiry"
                        description="Enquiry List"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />

                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        {isOpenDeleteModal && selectedId && (
                            <DeleteConfirmationModal
                                handleDelete={handleDelete}
                                isOpen={isOpenDeleteModal}
                                togglePopup={toggleDeletePopup}
                                id={selectedId}
                            />
                        )}

                        {isOpen && <AddEnquiryPopup togglePopup={togglePopup} isOpen={isOpen} onAddEnquireSubmit={onAddEnquireSubmit} selectedRow={selectedRow} dropdownOptions={dropdownOptions} />}

                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} getSelectedDates={getSelectedDates} />
                            </div>
                            <div className="flex">
                                {   enquiryList.length > 0 &&
                                    <div className="pr-[5px]">
                                        <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, enquiryList, "EnquiryList") }} />
                                    </div>
                                }
                                <div>
                                    <Button text="Add Enquiry" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePopup} />
                                </div>
                            </div>
                        </div>

                        <PaginationTable columnsData={columnsArray} rowData={enquiryList} />
                    </div>
                </>
            )}
        </>
    );
}

export default EnquiryList;


import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon  } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "../ContentHeader";
import UpdateWorkOrderStatusPopup from "../WorkOrder/UpdateWorkOrderStatusPopup";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { orderApi } from "@/utils/API";
import { addSerialNumbersToList } from "@/utils/CustomFunctions";
import { VIEW_WORK_ORDER_LIST_HEADER } from "../WorkOrder/Headers";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const ViewProductionWorkOrderList = () => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const { user: auth } = useAuth();

    const parentOrderId = queryParams.get("workOrderId");
    const quotationId = queryParams.get("quotationId");

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [workOrderList, setWorkOrderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [isListChanged, setIsListChanged] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);

    const toggleStatusPopup = () => {
        setSelectedRow({});
        setIsStatusPopupOpen(!isStatusPopupOpen);
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getSubOrders = async () => {
        setIsLoading(true);
        const url = isAdmin ? orderApi.getSuborders(parentOrderId) : orderApi.getSuborders(parentOrderId);
        //orderApi.getSubordersByUsername(parentOrderId, auth.username);
        const response = await APIService.get(url);
        const updatedList = await addSerialNumbersToList(response.orderList);
        setWorkOrderList(updatedList);
        setIsLoading(false);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${orderApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setIsListChanged(!isListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.EDIT_WO}?quotationId=${quotationId}&workOrderId=${rowData.id}&orderType=1`);
        } else if (action === "view") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_WO}?quotationId=${quotationId}&workOrderId=${rowData.id}&orderType=1&mode=view`);
        } else if (action === "generateMaterialRequest") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.RAISE_MAT_REQ}?workOrderId=${rowData.id}`);
        } else if (action === "viewMaterialRequest") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.MAT_REQ_LIST}?workOrderId=${rowData.orderId}`);
        } else if (action === "updateWOStatus") {
            setSelectedRow(rowData);
            setIsStatusPopupOpen(true);
        } else if (action === "productionUpdates") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.WO_UPDATES}?workOrderId=${rowData.id}&labelId=${rowData.orderId}`);
        } else if (action === "generateChassisNo") {
            onGenerateChassisNo(rowData.id);
        }
    };

    const onGenerateChassisNo = async (orderId) => {
        setIsLoading(true);
        let response = await APIService.patch(`${orderApi.generateChassis}${orderId}`, {});
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Chassis No Generated Successfully");
            setIsListChanged(!isListChanged);
        } else {
            console.log(response);
            const { error } = response?.data;
            toast.error(error);
        }
        setIsLoading(false);
    };

    const onUpdateStatusSubmit = async (payload) => {
        let response = await APIService.patch(`${orderApi.patch}${selectedRow.id}`, payload);
        if (response?.status === 200 || response?.status === 201) {
            toast.success("Status Updated Successfully");
        } else {
            console.log(response);
            const { error } = response?.data;
            toast.error(error);
        }

        setIsListChanged(!isListChanged);
        setSelectedRow({});
        setIsStatusPopupOpen(false);
    };

    const columnsArray = [
        ...VIEW_WORK_ORDER_LIST_HEADER,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => {
                return (
                    <>
                        <div className="text-[25] flex justify-center text-[#39ACE4]">
                            <EyeIcon {...icon} title="View Details" onClick={() => handleActionClick("view", row.original)} />
                            { isAdmin && <PencilIcon {...icon} title="Edit Order" onClick={() => handleActionClick("edit", row.original)} /> }
                            {/* { isAdmin && <TrashIcon {...icon} title="Delete Order" onClick={() => handleActionClick("delete", row.original)} /> } */}
                        </div>
                        <div className="text-[25] flex flex-col justify-center text-[#39ACE4] mt-4">
                            <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("generateMaterialRequest", row.original)}>Raise MR</span>
                            <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("viewMaterialRequest", row.original)}>View MR</span>
                            <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("updateWOStatus", row.original)}>Change Status</span>
                            <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("productionUpdates", row.original)}>Feed Updates</span>
                            { (row.original.status === "Paint Shop" && !row.original.chassisNumber) && <span className="text-[#39ACE4] text-[10px] my-1 flex self-center cursor-pointer" onClick={() => handleActionClick("generateChassisNo", row.original)}>Generate Chassis No.</span> }
                        </div>
                    </>
                    
                )
            },
        },
    ];

    useEffect(() => {
        getSubOrders();
    }, [isListChanged]);

    return (
        <>
            <ContentHeader
                title="Work Order"
                description={`Production Department / Work Order`}
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                {isStatusPopupOpen && <UpdateWorkOrderStatusPopup togglePopup={toggleStatusPopup} isOpen={isStatusPopupOpen} onUpdateStatus={onUpdateStatusSubmit} order={selectedRow} isAdmin={isAdmin} />}

                <div className="flex p-2.5 justify-between items-center">
                    <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                    { !isEmpty(workOrderList) && <>
                        <span className="text-[14px] text-red-500 font-small pt-3.5 pl-3.5">Work Order - {workOrderList[0].orderId.split("_")[0]}</span>
                        <div>
                            <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, workOrderList, "WorkOrderList") }} />
                        </div></>
                    }
                </div>
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    workOrderList && <PaginationTable columnsData={columnsArray} rowData={workOrderList} />
                )}
            </div>
        </>
    )
};

export default ViewProductionWorkOrderList;
export const DAILY_STATUS_FORM_VALUE = {
    additionalInfo: "",
    comments: "",
    createdDate: "",
    totalNumberOfPerson: 0,
    processEndTime: "",
    processStartTime: "",
    productionPersonalName: "",
    remark: "",
    status: "",
    subProcess: "",
    totalManHourTime: 0,
    totalTimeTaken: 0,
    workOrderId: "",
    skilled: {
        numberOfPerson: 0,
        totalManHourTime: 0
    },
    semiSkilled: {
        numberOfPerson: 0,
        totalManHourTime: 0
    },
    unSkilled: {
        numberOfPerson: 0,
        totalManHourTime: 0
    },
};

export const DAILY_STATUS_REQUIRED_FIELDS = [
    "status",
    "productionPersonalName",
    "subProcess",
    "skilled.numberOfPerson",
    "semiSkilled.numberOfPerson",
    "unSkilled.numberOfPerson",
    "remark",
    "comments"
];

export const DAILY_STATUS_ERROR_MESSAGE_VALUES = {
    status: "",
    productionPersonalName: "",
    subProcess: "",
    "skilled.numberOfPerson": "",
    "semiSkilled.numberOfPerson": "",
    "unSkilled.numberOfPerson": "",
    remark: "",
    comments: ""
};

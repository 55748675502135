import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import { PencilIcon, PrinterIcon } from "@heroicons/react/24/solid";

import "./DailyStatusAccordion.css";

const icon = {
    className: "w-6 h-6 text-[#39ACE4] mr-2 cursor-pointer"
};

const getFormattedDate = (date) => {
    if (date) {
        return moment(date).format("DD-MM-YYYY hh:mm:ss A").toString();
    }
    return "";
};

const AccordionContent = forwardRef((props, ref) => {
    const getTotalTimeTaken = () => {
        if (props.skilled.numberOfPerson && props.skilled.totalManHourTime) {
            return Number(props.skilled.totalManHourTime/props.skilled.numberOfPerson).toFixed(2);
        }
    
        if (props.semiSkilled.numberOfPerson && props.semiSkilled.totalManHourTime) {
            return Number(props.semiSkilled.totalManHourTime/props.semiSkilled.numberOfPerson).toFixed(2);
        }
    
        if (props.unSkilled.numberOfPerson && props.unSkilled.totalManHourTime) {
            return Number(props.unSkilled.totalManHourTime/props.unSkilled.numberOfPerson).toFixed(2);
        }
    };

    const getTotalPersons = () => {
        if (props.totalNumberOfPerson) {
            return props.totalNumberOfPerson;
        }

        return parseInt(props.skilled.numberOfPerson) + parseInt(props.semiSkilled
            .numberOfPerson) + parseInt(props.unSkilled.numberOfPerson)
    }

    return (
        <div className="panel" ref={ref}>
            <div className="mx-4 my-2">
                <table className="text-[14px] text-slate-700 w-full border mt-2">
                    <tbody>
                        <tr>
                            <td>Created By</td>
                            <td>{props.productionPersonalName}</td>
                            <td>Skilled Persons</td>
                            <td>{props.skilled.numberOfPerson}</td>
                        </tr>
                        <tr>
                            <td>Order Id</td>
                            <td>{props.workOrderId}</td>
                            <td>Semi-Skilled Persons</td>
                            <td>{props.semiSkilled.numberOfPerson}</td>
                            
                        </tr>
                        <tr>
                            <td>Order Status</td>
                            <td>{props.status}</td>
                            <td>Un-Skilled Persons</td>
                            <td>{props.unSkilled.numberOfPerson}</td>
                        </tr>
                        <tr>
                            <td>Current Process</td>
                            <td>{props.subProcess}</td>
                            <td>Total Skilled Hours</td>
                            <td>{props.skilled.totalManHourTime}</td>
                        </tr>
                        <tr>
                            <td>Process Start Time</td>
                            <td>{getFormattedDate(props.processStartTime)}</td>
                            <td>Total Semi-Skilled Hours</td>
                            <td>{props.semiSkilled.totalManHourTime}</td>
                        </tr>
                        <tr>
                            <td>Process End Time</td>
                            <td>{getFormattedDate(props.processEndTime)}</td>
                            <td>Total Un-Skilled Hours</td>
                            <td>{props.unSkilled.totalManHourTime}</td>
                        </tr>
                        <tr>
                            <td>Total Time (in Hrs)</td>
                            <td>{getTotalTimeTaken()}</td>
                            <td>Total Hours</td>
                            <td>{props.totalManHourTime}</td>
                        </tr>
                        <tr>
                            <td>Total Persons</td>
                            <td>{getTotalPersons()}</td>
                            <td>Remark</td>
                            <td>{props.remark}</td>
                        </tr>
                        <tr>
                            <td>Additional Information</td>
                            <td colSpan={3} className="w-9/12 text-left">{props.additionalInfo}</td>
                        </tr>
                        <tr>
                            <td>Comment</td>
                            <td colSpan={3} className="w-9/12 text-left">{props.comments}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
});

const DailyStatusAccordion = ({ active = false, data, isPrint = true, onEditStatus }) => {

    const componentRef = useRef();

    const takePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = () => {
        takePrint();
    };

    return (
        <>
            <div className="my-1">
                <button className="accordion min-w-full text-left border-0 outline-0 cursor-pointer ease-in duration-300 bg-slate-200 p-5 hover:bg-slate-300" title="">Created Date: <b>{getFormattedDate(data.createdDate)}</b></button>
                {active && <>
                    <div className="flex justify-end items-center mx-4 my-2">
                        {/* <PencilIcon {...icon} onClick={() => onEditStatus(data)} title="Edit Status" /> */}
                        {isPrint && <PrinterIcon {...icon} onClick={() => handlePrint()} title="Print Status" />}
                    </div>
                    <AccordionContent {...data} ref={componentRef} />
                </>}
            </div>
        </>
    );
};

export default DailyStatusAccordion;

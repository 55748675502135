export const MATERIAL_LIST_COLUMN  = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Created By",
        accessor: "createdBy",
        width: 5
    },
    {
        Header: "Name",
        accessor: "requestedMaterailInventoryList[0].name",
        width: 5
    },
    {
        Header: "Code",
        accessor: "requestedMaterailInventoryList[0].code",
        width: 5
    },
    {
        Header: "Quantity",
        accessor: "requestedMaterailInventoryList[0].quantity",
        width: 5
    },
    {
        Header: "Unit",
        accessor: "requestedMaterailInventoryList[0].unitOfMeasurement",
        width: 5
    },
    {
        Header: "Material Type",
        accessor: "requestedMaterailInventoryList[0].materialType",
        width: 5
    },
    {
        Header: "Stock Type",
        accessor: "requestedMaterailInventoryList[0].stockType",
        width: 5
    },
    {
        Header: "Remark",
        accessor: "requestedMaterailInventoryList[0].remark",
        width: 30
    },
    {
        Header: "Info",
        accessor: "requestedMaterailInventoryList[0].additionalInfo",
        width: 30
    },
    {
        Header: "Status",
        accessor: "requestedMaterailInventoryList[0].status",
        width: 5
    }
];
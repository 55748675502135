import { useRef, useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { IconButton } from "@material-tailwind/react";
import { Bars3CenterLeftIcon, UserCircleIcon } from "@heroicons/react/24/outline";

import { useAuth } from "@/hooks/useAuth";
import { usePermission } from "@/hooks/useRolePermission";
import { useMaterialTailwindController, setOpenSidenav } from "@/context/MaterialTailwindProvider";
import { getVisibleMenus } from "@/utils/CustomFunctions";
import routes from "@/routes/SideMenuRoutes";

import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { APP_URLS } from "@/routes";

const Container = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { permissions } = usePermission();
    const dropdownRef = useRef(null);
    const [controller, dispatch] = useMaterialTailwindController();
    const isAdmin = auth.user.roleList.indexOf("1") !== -1;
    const [isBurgerIconClick, setIsBurgerIconClick] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const visibleRoutes = getVisibleMenus(routes, permissions);

    const handleBurgerClick = () => {
        setIsBurgerIconClick(!isBurgerIconClick)
        setOpenSidenav(dispatch, false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const logOut = () => {
        auth.doLogout();
    };

    return (
        <>
            <div className="AppContainer h-auto">
                {
                    !isBurgerIconClick &&
                    <Sidebar routes={visibleRoutes} />
                }
                <div className={`w-auto ${!isBurgerIconClick ? "translate-x-0 ml-64" : "-translate-x-40 ml-52"}`}>
                    <div className="row sticky top-0 w-full z-10 bg-slate-50">
                        <div className="flex justify-end">
                            {/* <IconButton
                                variant="text"
                                color="black"
                                size="sm"
                                ripple={false}
                                className="relative py-4 grid rounded-br-none rounded-tl-none ml-2"
                                onClick={handleBurgerClick}
                            >
                                <Bars3CenterLeftIcon className="h-8 w-8 " />
                            </IconButton> */}
                            <div className={`relative inline-block text-left ${!isBurgerIconClick ? "mr-6" : ""}`} ref={dropdownRef}>
                                <button
                                    type="button"
                                    className="text-gray-500 hover:text-gray-700"
                                    onClick={toggleDropdown}
                                >
                                    <div className={`flex flex-col justify-center ${!isBurgerIconClick ? "pr-4" : ""}`}>
                                        <div className="flex items-center">
                                            <div className="mr-2">
                                                <span>
                                                    <UserCircleIcon className="h-8 w-8 text-black" />
                                                </span>
                                            </div>
                                            <div>
                                                <span className="mt-2 capitalize text-[14px] text-black">{auth?.user?.username}</span>
                                                {/* <span className="text-[12px] justify-end flex text-black">{auth?.user?.roleList?.join(", ")}</span> */}
                                            </div>
                                            <svg
                                                className={`ml-2 inline-block w-4 h-4 ${dropdownOpen ? "transform rotate-180" : ""}`}
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M6.293 5.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </button>

                                {dropdownOpen && (
                                    <div className="origin-top-right text-[14px] absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            { isAdmin &&
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    onClick={() => {
                                                        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SETTGS}`);
                                                    }}
                                                >
                                                    Settings
                                                </a>
                                            }
                                            <a
                                                href="#"
                                                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                onClick={() => logOut()}
                                            >
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div id="main" className="bg-slate-100 pb-2" style={{minHeight: 580}}>
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Container;

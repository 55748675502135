import { createContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useAuth } from '../hooks/useAuth';
import { APIService } from "@/services";
import { userRoleApi } from "@/utils/API";

export const RolePermissionContext = createContext();

const RolePermissionProvider = ({ children }) => {
    const { user } = useAuth();
    const [role, setRole] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const setPermissionList = () => {
        if (!isEmpty(user?.rolePermission)) {
            let permissions = [];
            user?.rolePermission.forEach(item => {
                item?.modulePermission.forEach(p => {
                    permissions.push(p);
                });
            });
            setPermissions(permissions);
        }
    };

    const setRoleList = async () => {
        const roleId = user.roleList[0];
        const url = `${userRoleApi.filterRole}?fields=id,name,description&filterAnd=id%7Ceq%7C${roleId}`;
        const response = await APIService.get(url);
        if (!isEmpty(response.roleList)) {
            setRole(response.roleList[0]);
        }
    };

    useEffect(() => {
        if (user) {
            setPermissionList();
            setRoleList();
        }
    }, [user]);

    return (
        <RolePermissionContext.Provider value={{permissions, role}}>
            {children}
        </RolePermissionContext.Provider>
    );
};

export default RolePermissionProvider;

export const BALANCE_INFORMATION_COLUMN  = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 2
    },
    {
        Header: "Balance Amount",
        accessor: "balanceAmount",
        width: 10
    },
    {
        Header: "Sales Person Id",
        accessor: "salesPersonId",
        width: 10
    },
    {
        Header: "Sales Person Name",
        accessor: "salesPersonName",
        width: 10
    }
];
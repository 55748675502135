import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { APIService } from "@/services";
import { userApi, loginServiceApi } from "@/utils/API";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")) || null);
    const [token, setToken] = useState(sessionStorage.getItem("token") || "");
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const offlineApi = userApi.offline + user?.username;
            const response = await APIService.post(offlineApi);
            const { accessToken, customerId, rolePermission, roleList } = response.data;
            const refreshedUser = { customerId, rolePermission, roleList, username: user?.username };

            setToken(accessToken);
            setUser((prevUser) => ({ ...prevUser, ...refreshedUser }));
            sessionStorage.setItem("user", JSON.stringify(refreshedUser));
            sessionStorage.setItem("token", accessToken);
            
        } catch (error) {
            setUser(null);
            setToken("");
        }
    };

    const login = async (payload) => {
        try {
            const response = await APIService.post(loginServiceApi.post, payload);
            if (response?.data) {
                const { accessToken, customerId, roleList, rolePermission } = response.data;
                let user = {
                    customerId,
                    rolePermission,
                    roleList,
                    username: payload.username
                };

                setToken(accessToken);
                setUser(user);
                sessionStorage.setItem("user", JSON.stringify(user));
                sessionStorage.setItem("token", accessToken);
                return true;
            } else {
                return false;
            }
        } catch( error) {
            console.log(error);
            return false;
        }
    };

    const logout = () => {
        setUser(null);
        setToken("");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("isActiveMenu");
        toast.success('Successfully Logout');
        navigate("/login");
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken();
        }, 12 * 60 * 1000); //refresh every 12 minutes

        return () => clearInterval(interval);
    }, [user]);

    return (
        <AuthContext.Provider value={{ user, token, doLogin: login, doLogout: logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const INITIAL_FORM_VALUE = {
    additionalInfo: "",
    code: "",
    description: "",
    materialType: "",
    name: "",
    quantity: "",
    remark: "",
    stockType: "",
    unitOfMeasurement: ""
};

export const REQUIRED_FIELDS = [
    "code",
    "description",
    "materialType",
    "name",
    "quantity",
    "stockType",
    "unitOfMeasurement"
];

export const ERROR_MESSAGE_VALUES = {
    additionalInfo: "",
    code: "",
    description: "",
    materialType: "",
    name: "",
    quantity: "",
    remark: "",
    stockType: "",
    unitOfMeasurement: ""
};
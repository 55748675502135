import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import { EyeIcon } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "@/pages/ContentHeader";
import { WORK_ORDER_PAYMENT_LIST_COLUMN } from "./PaymentListColumn";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { accountApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { filterPaymentData, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const WorkOrderPaymentList = () => {
    const navigate = useNavigate();
    const { user: auth } = useAuth();

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [isLoading, setIsLoading] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const [originalPaymentList, setOriginalPaymentList] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const getPaymentListData = async () => {
        setIsLoading(true);

        const response = await APIService.get(accountApi.getAllPayment());
        setIsLoading(false);
        if (response?.statusList && response?.statusList.length) {
            const updatedList = await addSerialNumbersToList(response.statusList);
            setPaymentList(updatedList);
            setOriginalPaymentList(updatedList);
        }
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "add_payment") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.ACC_DEPT_ADD_PAY}`);
            return;
        };

        if (action === "view") {
            const queryParams = `?id=${rowData?.id}`;
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.ACC_DEPT_VIEW_RECEIP_LIST}` + queryParams);
        } else if (action === "delete") {
        }
    };

    const filterDataFunction = (searchInput) => {
        const { value } = searchInput?.target
        const filteredPaymentList = filterPaymentData(value, originalPaymentList);
        if (filteredPaymentList.length === 0) {
            setPaymentList(originalPaymentList);
        } else {
            setPaymentList(filteredPaymentList ?? []);
        }
    };

    const getSelectedDates = (data) => {
        const startDate = data[0];
        const endDate = data[1];

        if (startDate !== null && endDate !== null) {
            const startDateToFilter = new Date(startDate);
            startDateToFilter.setHours(0, 0, 0, 0);
            const endDateToFilter = new Date(endDate);
            endDateToFilter.setHours(0, 0, 0, 0);

            const filteredPaymentList = originalPaymentList.filter(item => {
                const orderDate = new Date(item.createdOn);
                orderDate.setHours(0, 0, 0, 0);
                return (
                    orderDate >= startDateToFilter &&
                    orderDate <= endDateToFilter
                );
            });
            setPaymentList(filteredPaymentList ?? []);
        } else {
            setPaymentList(originalPaymentList);
        }
    };

    useEffect(() => {
        getPaymentListData();
    }, []);
    
    const columnsArray = [
        ...WORK_ORDER_PAYMENT_LIST_COLUMN,
        {
            Header: "Action",
            accessor: "action",
            width: 2,
            Cell: ({ value, row }) => {
                return (
                    <div className=" flex justify-center">
                        <EyeIcon {...icon} onClick={() => handleActionClick("view", row.original) } title="View Receipt" />
                    </div>
                );
            }
        }
    ];

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Account Department"
                        description="Payments"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            {paymentList.length > 0 &&
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} getSelectedDates={getSelectedDates} />
                            </div>}
                            <div className="flex">
                                {paymentList.length > 0 &&
                                <div className="pr-[5px]">
                                    <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, paymentList, "WorkOrderPaymentList") }} />
                                </div>}
                                <div>
                                    <Button text="Add Payment" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={() => handleActionClick("add_payment", null) } title="Add Payment" />
                                </div>
                            </div>
                        </div>
                        <PaginationTable columnsData={columnsArray} rowData={paymentList} />
                    </div>
                </>
            )}
        </>
    );
}

export default WorkOrderPaymentList;

export const INITIAL_FORM_VALUE = {
    customerName: "",
    orderId: "",
    paidAmountSummary: {
        additionalInformation: "",
        additionalInformation1: "",
        amountInWords: "",
        bankName: "",
        checkNumber: "",
        modeOfPayment: "",
        paidAmount: 0,
        customerName: ""
    },
    salesPersonId: "",
    salesPersonName: ""
};

export const REQUIRED_FIELDS = [
    "orderId",
    "paidAmountSummary.additionalInformation",
    "paidAmountSummary.bankName",
    "paidAmountSummary.checkNumber",
    "paidAmountSummary.modeOfPayment",
    "paidAmountSummary.paidAmount",
    "paidAmountSummary.customerName",
    "salesPersonId"
];

export const ERROR_MESSAGE_VALUES = {
    "orderId": "",
    "paidAmountSummary.additionalInformation": "",
    "paidAmountSummary.bankName": "",
    "paidAmountSummary.checkNumber": "",
    "paidAmountSummary.modeOfPayment": "",
    "paidAmountSummary.paidAmount": "",
    "paidAmountSummary.customerName": "",
    "salesPersonId": ""
};

export const PAYMENT_MODES = [
    { id: "rtgs", name: "RTGS" },
    { id: "imps", name: "IMPS" },
    { id: "neft", name: "NEFT" },
    { id: "cheque", name: "CHEQUE" },
    { id: "upi", name: "UPI" }
];

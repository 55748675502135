export const CATEGORY_HEADER = [
    {
        Header: "Serial No.",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Name",
        accessor: "name",
        width: 25
    },
    {
        Header: "Description",
        accessor: "description",
        width: 25
    },
];
export const INVENTORY_PRDODUCT_HEADER = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Material Code",
        accessor: "code",
        width: 20
      },
    {
        Header: "Material Short Name",
        accessor: "name",
        width: 20
    },
    {
        Header: "Material Long Description",
        accessor: "description",
        width: 25
    },
    {
        Header: "Unit of Measurement (UoM)",
        accessor: "purchaseUnit",
        width: 15
    },
    {
        Header: "Material Type",
        accessor: "goodsType",
        width: 20
    },
    {
        Header: "Unit Rate (INR)",
        accessor: "perUnitRate",
        width: 15
    },
    {
        Header: "Stock Type",
        accessor: "stockType",
        width: 15
    },
    
    {
        Header: "Reorder Level",
        accessor: "reOrderLevel",
        width: 15
    },
    {
        Header: "Is Scrap Goods",
        accessor: "isScrapGoods", // Assuming this is the accessor for the dropdown
        width: 15
    },
    {
        Header: "HSN CODE",
        accessor: "hsnCode",
        width: 15
    },
    {
        Header: "Remrark",
        accessor: "remark",
        width: 15
    },
    {
        Header: "ELV",
        accessor: "estimatedLifeValue",
        width: 15
    },
];
export const INVENTORY_STOCK_ADDED_HEADER =  [
    {
        Header: "ID",
        accessor: "id",
        width: 10
    },
    
    {
        Header: "Code",
        accessor: "code",
        width: 10
    },
    {
        Header:"Name",
        accessor:"name",
        width:10
    },
    {
       Header : "Unit",
       accessor:"salesUnit"  
    },
    {
        Header: "PO Number",
        accessor: "poNumber",
        width: 10
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        width: 10
    },
    {
        Header:"Price",
        accessor:"price",
        width:10
    },
    {
        Header: "Created By",
        accessor: "createdBy",
        width: 15
    },
    {
        Header: "Created On",
        accessor: "createdOn",
        width: 15
    },
    {
        Header: "Link",
        accessor: "href",
        width: 10
    },
    {
        Header: "Last Updated",
        accessor: "lastUpdated",
        width: 15
    },
    {
        Header: "Last Updated By",
        accessor: "lastUpdatedBy",
        width: 15
    },
];

export const PRDODUCT_HEADER = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Name",
        accessor: "name",
        width: 25
    },
    {
        Header: "Description",
        accessor: "description",
        width: 25
    },
    {
        Header: "Category",
        accessor: "categoryName",
        width: 25
    },
    {
        Header: "Original Price",
        accessor: "originalPrice",
        width: 25
    },
    {
        Header: "Stock Price",
        accessor: "stock",
        width: 25
    },
];

import React, { useState, useEffect } from "react";
import { isNull, isEmpty, map } from "lodash";
import { Blocks } from "react-loader-spinner";
import { toast } from "react-toastify";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal/DeleteConfirmationModal";
import TableCustomFilter from "@/components/TableCustomFilter";
import ContentHeader from "../ContentHeader";
import AddUserPopup from "./AddUserPopup";
import AddUserRolePopup from "./AddRolePopup";
import AddPermissionPopup from "./AddPermissionPopup";
import ChangePasswordPopup from "./ChangePasswordPopup";
import { USER_COLUMNS, ROLE_COLUMNS, PERMISSION_COLUMNS } from "./UserListColumn";

import { APIService } from "@/services";
import { userApi, userRoleApi, loginServiceApi, permissionApi } from "@/utils/API";
import { filterUserData, filterRoleData, filterPermissionData } from "@/utils/CustomFunctions";
import { icon } from "@/utils/AppConstants";

const UserAccounts = () => {
    const [layout, setLayout] = useState("User")
    const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
    const [isRolePopupOpen, setIsRolePopupOpen] = useState(false);
    const [isPermissionPopupOpen, setIsPermissionPopupOpen] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isPasswordResetPopupOpen, setIsPasswordResetPopupOpen] = useState(false);

    const [userData, setUserData] = useState(null);
    const [roleData, setRoleData] = useState(null);
    const [permissionData, setPermissionData] = useState(null);
    const [originalUserData, setOriginalUserData] = useState(null);
    const [originalRoleData, setOriginalRoleData] = useState(null);
    const [originalPermissionData, setOriginalPermissionData] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [selectedSction, setSelectedSection] = useState("");
    const [userPlaceListChanged, setUserPlaceListChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleLayout = (layout) => {
        if (layout === "User") {
            setLayout("User");
        } else if (layout === "Role") {
            setLayout("Role");
        } else if (layout === "Permission") {
            setLayout("Permission");
        }
    };

    const toggleUserPopup = () => {
        setSelectedRow({});
        setIsUserPopupOpen(!isUserPopupOpen);
    };

    const toggleRolePopup = () => {
        setSelectedRow({});
        setIsRolePopupOpen(!isRolePopupOpen);
    };

    const togglePermissionPopup = () => {
        setSelectedRow({});
        setIsPermissionPopupOpen(!isPermissionPopupOpen);
    }; 

    const togglePasswordPopup = () => {
        setSelectedRow({});
        setIsPasswordResetPopupOpen(!isPasswordResetPopupOpen);
    };

    const getData = async (layout) => {
        setIsLoading(true);
        if (layout === "User") {
            const response = await APIService.get(userApi.filter)
            const userGetData = response?.userList?.map((user, index) => ({
                ...user,
                fullName: `${user.familyName} ${user.givenName}`,
                roleListName: map(user.roleDetails, "roleName").join(", "),
                serialNumber: index + 1
            })) ?? [];
            setUserData(userGetData ?? []);
            setOriginalUserData(userGetData ?? []);
        } else if (layout === "Role") {
            const response = await APIService.get(userRoleApi.filter)
            const userGetRoleData = response?.roleList?.map((user, index) => ({
                ...user,
                serialNumber: index + 1
            })) ?? [];
            setRoleData(userGetRoleData ?? []);
            setOriginalRoleData(userGetRoleData ?? []);
        } else if (layout === "Permission") {
            const response = await APIService.get(permissionApi.filter);
            const userPermissionData = response?.userPermissionList?.map((permission, index) => ({
                ...permission,
                serialNumber: index + 1
            })) ?? [];
            setPermissionData(userPermissionData ?? []);
            setOriginalPermissionData(userPermissionData ?? []);
        }
        setIsLoading(false);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        let deleteUrl;
        if (selectedSction === "deleteRole") {
            deleteUrl = `${userRoleApi.delete}${id}`;
        } else if (selectedSction === "deleteUser") {
            deleteUrl = `${userApi.delete}${id}`;
        } else if (selectedSction === "deletePermission") {
            deleteUrl = `${permissionApi.delete}${id}`;
        }

        const response = await APIService.delete(deleteUrl);
        if (response.status === 204 || response.status === 200) {
            toast.success(`${layout} deleted successfully!`);
            getData(layout);
        } else {
            toast.error(`${layout} deletion failure!`);
        }

        setSelectedId("");
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        setSelectedSection(action);
        if (action === "deleteUser") {
            const id = rowData?.id
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "editUser") {
            setSelectedRow(rowData)
            setIsUserPopupOpen(true)
        } else if (action === "deleteRole") {
            const id = rowData?.id
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "editRole") {
            setSelectedRow(rowData);
            setIsRolePopupOpen(true);
        } else if (action === "editPassword") {
            setSelectedRow(rowData);
            setIsPasswordResetPopupOpen(true);
        } else if (action === "editPermission") {
            setSelectedRow(rowData);
            setIsPermissionPopupOpen(true);
        } else if (action === "deletePermission") {
            const id = rowData?.id
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        }
    };

    /** Add User */
    const onAddUserSubmit = async (data, action) => {
        const id = selectedRow?.id;

        let response;
        if (action === "") {
            response = await APIService.post(userApi.post, data);
        } else {
            response = await APIService.patch(`${userApi.patch}${id}`, data);
        }

        if (response?.status === 200 || response?.status === 201) {
            if (response?.data.userId !== "" && action !== "edit") {
                toast.success("User added successfully!");
            } else {
                toast.success("User updated successfully!");
            }
        } else {
            if (response?.data?.errorCode === "user_already_exists") {
                toast.error("User already exists");
            }
            if (response?.message) {
                toast.error(response?.message);
            }
        }
        
        setSelectedRow({});
        setIsUserPopupOpen(false);
        getData("User");
    };

    /** Add Role */
    const onAddRoleSubmit = async (data, action) => {
        const id = selectedRow?.id;

        let response;
        if (action === "") {
            response = await APIService.post(userRoleApi.post, data);
        } else {
            response = await APIService.patch(`${userRoleApi.patch}${id}`, data);
        }

        if (response?.status === 200 || response?.status === 201) {
            if (response?.name !== "" && action !== "edit") {
                toast.success("Role added successfully!");
            } else {
                toast.success("Role updated successfully!");
            }
        } else {
            if (response?.data?.errorCode === "role_already_exists") {
                toast.error("Role already exists");
            }
            if (response?.message) {
                toast.error(response?.message);
            }
        }

        setSelectedRow({});
        setIsRolePopupOpen(false);
        getData("Role");
    };

    /** Add Permission */
    const onAddPermissionSubmit = async (data, action) => {
        const id = selectedRow?.id;
        let response;
        if (action === "") {
            response = await APIService.post(permissionApi.post, data);
        } else {
            response = await APIService.patch(`${permissionApi.patch}${id}`, data);
        }

        if (response?.status === 200 || response?.status === 201) {
            if (action !== "edit") {
                toast.success("Permission added successfully!");
            } else {
                toast.success("Permission updated successfully!");
            }
        } else {
            if (response?.message) {
                toast.error(response?.message);
            }
        }

        setSelectedRow({});
        setIsPermissionPopupOpen(false);
        getData("Permission");
    };

    /** Change Password */
    const onChangePasswordSubmit = async (data) => {
        let response = await APIService.patch(`${loginServiceApi.resetPassword}`, data);
        if (response?.status === 200 || response?.status === 201) {
            toast.success(`Password Changed`);
        } else {
            toast.error(response?.message || "Error, Password not changed. Please try again!");
        }
        
        setIsPasswordResetPopupOpen(false);
        getData("User");
    };

    // function to filter orders based on search input
    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target;
        let filteredData;
        if (layout === "User") {
            filteredData = filterUserData(value, originalUserData);
            setUserData((filteredData?.length == 0) ? originalUserData : filteredData);
        } else if (layout === "Role") {
            filteredData = filterRoleData(value, originalRoleData);
            setRoleData((filteredData?.length == 0) ? originalRoleData : filteredData);
        } else if (layout === "Permission") {
            filteredData = filterPermissionData(value, originalPermissionData);
            setPermissionData((filteredData?.length == 0) ? originalPermissionData : filteredData);
        }
    };

    const userColumns = [
        ...USER_COLUMNS,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <TrashIcon title="Delete User" {...icon} onClick={() => handleActionClick("deleteUser", row.original)} />
                    <PencilIcon title="Edit User"  {...icon} onClick={() => handleActionClick("editUser", row.original)} />
                    <span className="text-[#39ACE4] text-[10px] ml-2 flex self-center cursor-pointer" onClick={() => handleActionClick("editPassword", row.original)}>Change Password</span>
                </div>
            ),
        },
    ];

    const roleColumns = [
        ...ROLE_COLUMNS,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <TrashIcon title="Delete Role" {...icon} onClick={() => handleActionClick("deleteRole", row.original)} />
                    <PencilIcon title="Edit Role"  {...icon} onClick={() => handleActionClick("editRole", row.original)} />
                </div>
            ),
        },
    ];

    const permissionColumns = [
        ...PERMISSION_COLUMNS,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <TrashIcon title="Delete Permission" {...icon} onClick={() => handleActionClick("deletePermission", row.original)} />
                    <PencilIcon title="Edit Permission"  {...icon} onClick={() => handleActionClick("editPermission", row.original)} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        getData("Role");
        getData(layout);
    }, [layout]);

    return (
        <>
            <ContentHeader
                title="Users & Roles"
                description="Users & Roles"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                filterDataFunction={filterDataFunction}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}
                {isUserPopupOpen && !isNull(roleData) && <AddUserPopup toggleUserPopup={toggleUserPopup} isOpen={isUserPopupOpen} onAddUserSubmit={onAddUserSubmit} selectedRow={selectedRow} roleData={roleData} />}
                {isRolePopupOpen && <AddUserRolePopup toggleRolePopup={toggleRolePopup} isOpen={isRolePopupOpen} onAddRoleSubmit={onAddRoleSubmit} selectedRow={selectedRow} />}
                {isPermissionPopupOpen && <AddPermissionPopup togglePermissionPopup={togglePermissionPopup} isOpen={isPermissionPopupOpen} onAddPermssionSubmit={onAddPermissionSubmit} selectedRow={selectedRow} roleData={roleData} />}
                {isPasswordResetPopupOpen && <ChangePasswordPopup togglePasswordPopup={togglePasswordPopup} isOpen={isPasswordResetPopupOpen} onChangePasswordSubmit={onChangePasswordSubmit} selectedRow={selectedRow} />}

                <div className="text-xl pt-3.5 pl-3.5 flex justify-between">
                    <div className="border-1 flex gap-4">
                        <Button text="Users" textColor={layout === "User" ? "white" : "black"} bgColor={layout === "User" ? "[#39ACE4]" : "[#E9ECF6]"} onClick={() => handleLayout("User")} />
                        <Button text="Roles" textColor={layout === "Role" ? "white" : "black"} bgColor={layout === "Role" ? "[#39ACE4]" : "[#E9ECF6]"} onClick={() => handleLayout("Role")} />
                        <Button text="Permissions" textColor={layout === "Permission" ? "white" : "black"} bgColor={layout === "Permission" ? "[#39ACE4]" : "[#E9ECF6]"} onClick={() => handleLayout("Permission")} />
                    </div>
                    <div className="border-1 mr-5">
                        {layout === "User" && <Button text="Add User" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={toggleUserPopup} />}
                        {layout === "Role" && <Button text="Add Roles" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={toggleRolePopup} />}
                        {layout === "Permission" && <Button text="Add Permission" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePermissionPopup} />}
                    </div>
                </div>
                {isLoading &&
                    <div className='flex items-center justify-center'>
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                }
                {!isLoading &&
                <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                    <div className="flex space-x-3">
                        <TableCustomFilter filterDataFunction={filterDataFunction}  />
                    </div>
                </div>}
                {layout === "User" && !isNull(userData) && <div className="flex flex-col rounded h-auto bg-white">
                    <PaginationTable columnsData={userColumns} rowData={userData} />
                </div>}
                {layout === "Role" && !isNull(roleData) && <div className="flex flex-col rounded h-auto bg-white">
                    <PaginationTable columnsData={roleColumns} rowData={roleData} />
                </div>}
                {layout === "Permission" && !isNull(permissionData) && <div className="flex flex-col rounded h-auto bg-white">
                    <PaginationTable columnsData={permissionColumns} rowData={permissionData} />
                </div>}
            </div>
        </>
    );
}

export default UserAccounts;

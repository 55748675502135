import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Blocks } from "react-loader-spinner";

import Button from "@/components/Button";
import ContentHeader from "@/pages/ContentHeader";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import { APIService } from "@/services";
import { accountApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { RECEIPT_LIST_COLUMN } from "../../AccountDepartment/WorkOrderPaymentList/PaymentListColumn";
import { filterReceiptData, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const ViewWorkOrderReceiptList = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [originalResponse, setOriginalResponse] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [receiptList, setReceiptList] = useState([]);
    const [originalReceiptList, setOriginalReceiptList] = useState([]);

    const id = queryParams.get("id");

    const getReceiptList = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${accountApi.get}/${id}`);
        if (!isEmpty(response.status)) {
            let updatedList = response.status?.receiptInfo.sort((a, b) => Number(b.receiptNumber) - Number(a.receiptNumber));
            updatedList = await addSerialNumbersToList(updatedList);
            setOriginalResponse(response.status);
            setOrderId(response.status?.orderId);
            setReceiptList(updatedList);
            setOriginalReceiptList(updatedList);
        }
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "view") {
            const queryParams = `?id=${id}&receiptId=${rowData?.receiptNumber}`;
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALES_WO_VIEW_RECEIPT}` + queryParams);
            return;
        }
    };

    const filterDataFunction = (searchInput) => {
        const { value } = searchInput?.target
        const filteredReceiptList = filterReceiptData(value, originalReceiptList);
        if (filteredReceiptList.length === 0) {
            setReceiptList(originalReceiptList);
        } else {
            setReceiptList(filteredReceiptList ?? []);
        }
    };

    const getSelectedDates = (data) => {
        const startDate = data[0];
        const endDate = data[1];

        if (startDate !== null && endDate !== null) {
            const startDateToFilter = new Date(startDate);
            startDateToFilter.setHours(0, 0, 0, 0);
            const endDateToFilter = new Date(endDate);
            endDateToFilter.setHours(0, 0, 0, 0);

            const filteredPaymentList = originalReceiptList.filter(item => {
                const lastUpdatedDate = new Date(item.lastUpdated);
                lastUpdatedDate.setHours(0, 0, 0, 0);
                return (
                    lastUpdatedDate >= startDateToFilter &&
                    lastUpdatedDate <= endDateToFilter
                );
            });
            setReceiptList(filteredPaymentList ?? []);
        } else {
            setReceiptList(originalReceiptList);
        }
    };

    useEffect(() => {
        getReceiptList();
    }, []);

    const columnsArray = [
        ...RECEIPT_LIST_COLUMN,
        {
            Header: "Action",
            accessor: "action",
            width: 2,
            Cell: ({ value, row }) => {
                return (
                    <div className=" flex justify-center">
                        <span className="text-[#39ACE4] text-[12px] mr-2 flex self-center cursor-pointer" onClick={() => handleActionClick("view", row.original)} title="View Receipt">View Receipt</span>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Sales Department"
                        description="Work Order Receipts"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} getSelectedDates={getSelectedDates} />
                            </div>
                            <div className="flex justify-center">
                                {   receiptList.length > 0 && <>
                                        <div className="m-2">
                                            <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                                        </div>
                                        <div className="m-2">
                                            <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, receiptList, `WorkOrderReceiptList_${orderId}`) }} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <PaginationTable columnsData={columnsArray} rowData={receiptList} />
                    </div>
                </>
            )}
        </>
    );
};

export default ViewWorkOrderReceiptList;
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { Blocks } from "react-loader-spinner";
import { EyeIcon, PencilIcon, TrashIcon  } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import ContentHeader from "@/pages/ContentHeader";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import { APIService } from "@/services";
import { accountApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { RECEIPT_LIST_COLUMN } from "../WorkOrderPaymentList/PaymentListColumn";
import { filterReceiptData, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const WorkOrderReceiptList = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isReceiptListChanged, setIsReceiptListChanged] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedReceiptId, setSelectedReceiptId] = useState(null);
    const [originalResponse, setOriginalResponse] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [receiptList, setReceiptList] = useState([]);
    const [originalReceiptList, setOriginalReceiptList] = useState([]);

    const id = queryParams.get("id");

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getReceiptList = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${accountApi.get}/${id}`);
        if (!isEmpty(response.status)) {
            let updatedList = response.status?.receiptInfo.sort((a, b) => Number(b.receiptNumber) - Number(a.receiptNumber));
            updatedList = await addSerialNumbersToList(updatedList);
            setOriginalResponse(response.status);
            setOrderId(response.status?.orderId);
            setReceiptList(updatedList);
            setOriginalReceiptList(updatedList);
        }
        setIsLoading(false);
    };

    const handleDelete = async (receiptNumber) => {
        setIsPending(true);
        const response = await APIService.delete(`${accountApi.delete}${originalResponse.id}/${receiptNumber}`);
        if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
            toast.success(`${receiptNumber} Deleted Successfully`);
            setIsReceiptListChanged(!isReceiptListChanged);
        } else {
            toast.error(`Receipt - ${receiptNumber} Deletion Request Failure`);
        }

        setSelectedReceiptId(null);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setIsPending(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "view") {
            const queryParams = `?id=${id}&receiptId=${rowData?.receiptNumber}`;
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.ACC_DEPT_VIEW_RECEIPT}` + queryParams);
            return;
        } else if (action === "edit") {
            const queryParams = `?id=${id}&receiptId=${rowData?.receiptNumber}`;
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.ACC_DEPT_EDIT_PAY}` + queryParams);
            return;
        } else if (action === "delete") {
            setSelectedReceiptId(rowData?.receiptNumber)
            setIsOpenDeleteModal(!isOpenDeleteModal);
        }
    };

    const filterDataFunction = (searchInput) => {
        const { value } = searchInput?.target
        const filteredReceiptList = filterReceiptData(value, originalReceiptList);
        if (filteredReceiptList.length === 0) {
            setReceiptList(originalReceiptList);
        } else {
            setReceiptList(filteredReceiptList ?? []);
        }
    };

    const getSelectedDates = (data) => {
        const startDate = data[0];
        const endDate = data[1];

        if (startDate !== null && endDate !== null) {
            const startDateToFilter = new Date(startDate);
            startDateToFilter.setHours(0, 0, 0, 0);
            const endDateToFilter = new Date(endDate);
            endDateToFilter.setHours(0, 0, 0, 0);

            const filteredPaymentList = originalReceiptList.filter(item => {
                const lastUpdatedDate = new Date(item.lastUpdated);
                lastUpdatedDate.setHours(0, 0, 0, 0);
                return (
                    lastUpdatedDate >= startDateToFilter &&
                    lastUpdatedDate <= endDateToFilter
                );
            });
            setReceiptList(filteredPaymentList ?? []);
        } else {
            setReceiptList(originalReceiptList);
        }
    };

    useEffect(() => {
        getReceiptList();
    }, [isReceiptListChanged]);

    const columnsArray = [
        ...RECEIPT_LIST_COLUMN,
        {
            Header: "Action",
            accessor: "action",
            width: 2,
            Cell: ({ value, row }) => {
                return (
                    <div className=" flex justify-center">
                        <span className="text-[#39ACE4] text-[12px] mr-2 flex self-center cursor-pointer" onClick={() => handleActionClick("view", row.original)} title="View Receipt">View Receipt</span>
                        <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original) } title="Edit Receipt" />
                        <TrashIcon {...icon} onClick={() => handleActionClick("delete", row.original) } title="View Receipt" />
                    </div>
                );
            }
        }
    ];

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Account Department"
                        description="Payments"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />
                    <div className="m-4 flex flex-col rounded h-auto bg-white">
                        {isOpenDeleteModal && selectedReceiptId && (
                            <DeleteConfirmationModal
                                handleDelete={handleDelete}
                                isOpen={isOpenDeleteModal}
                                togglePopup={toggleDeletePopup}
                                id={selectedReceiptId}
                                showLoader={isPending}
                            />
                        )}

                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                <TableCustomFilter filterDataFunction={filterDataFunction} getSelectedDates={getSelectedDates} />
                            </div>
                            <div className="flex">
                                {   receiptList.length > 0 &&
                                    <div className="pr-[5px]">
                                        <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, receiptList, `WorkOrderReceiptList_${orderId}`) }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <PaginationTable columnsData={columnsArray} rowData={receiptList} />
                    </div>
                </>
            )}
        </>
    );
};

export default WorkOrderReceiptList;
import React, { useEffect, useState } from 'react';
import { isEmpty, pullAt, get, cloneDeep } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Blocks, ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";

import Dropdown from "@/components/Dropdown";
import Button from "@/components/Button";
import InputField from "@/components/InputField"
import ErrorMessage from "@/components/ErrorMessage"
import ContentHeader from "../ContentHeader";

import { useData } from "@/hooks/useData";
import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { orderApi, materialRequestApi } from "@/utils/API";
import {
    REQUIRED_FIELDS,
    INITIAL_FORM_VALUE,
    ERROR_MESSAGE_VALUES
} from "./constants";
import { APP_URLS } from "@/routes";
import { REGEX } from "@/utils/AppConstants";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const RaiseMaterialRequest = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const { data } = useData();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [formList, setFormList] = useState([INITIAL_FORM_VALUE]);
    const [formErrorList, setFormErrorList] = useState([ERROR_MESSAGE_VALUES]);
    const [order, setOrder] = useState({});

    const orderId = queryParams.get("workOrderId");
    const requestId = queryParams.get("requestId");
    const mode = (requestId) ? "edit" : "new";

    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedFormList = cloneDeep(formList);
        if (name === 'quantity') {
            updatedFormList[index][name] = isNaN(value) ? value : Number(value);
        } else {
            updatedFormList[index][name] = value;
        }
        setFormList(updatedFormList);
    };

    const handleOptionSelect = (index, option, id) => {
        patchFields(option, index, id);
    };

    const selectedOption = (id, options, field) => {
        const selectedValue = options.find((option) => {
            return option[field] === id
        });
        return selectedValue;
    };

    const patchFields = (option, index, id) => {
        const updatedFormList = cloneDeep(formList);
        updatedFormList[index][id] = option?.name;
        updatedFormList[index]['code'] = option?.code || 'Please mention code';
        updatedFormList[index]['description'] = option?.code || 'Please description';
        updatedFormList[index]['materialType'] = option?.goodsType || 'Please mention material type';
        updatedFormList[index]['stockType'] = option?.stockType || 'Please mention stock type';
        updatedFormList[index]['unitOfMeasurement'] = option?.salesUnit || 'Please mention unit';
        updatedFormList[index]['remark'] = option?.remark || 'Please remark';
        setFormList(updatedFormList);
    };

    const resetForm = () => {
        setFormList([INITIAL_FORM_VALUE]);
    };

    const handleSubmit = async () => {
        setIsPending(true);

        let newErrorMessages = [];
        formList.forEach((form, index) => {
            newErrorMessages[index] = {};
            REQUIRED_FIELDS.forEach((field) => {
                if (!get(form, field)) {
                    newErrorMessages[index][field] = "This field is required.";
                } else {
                    newErrorMessages[index][field] = "";
                    if (field === "quantity" && !get(form, field).toString().match(REGEX.POSITIVE_GRT_ZERO)) {
                        newErrorMessages[index][field] = "Please enter valid input";
                    }
                }
            });
        });
        setFormErrorList(newErrorMessages);

        let isError = false;
        newErrorMessages.forEach((formError) => {
            if (Object.values(formError).some((errorMsg) => errorMsg !== "")) {
                setIsPending(false);
                isError = true;
            };
        });

        if (!isError) {
            let payload = {
                requestedMaterialInventoryList: formList,
                requesterName: user.username,
                workOrderId: order.orderId
            }

            let response = await APIService.post(materialRequestApi.post, payload);
            setIsPending(false);
            if (response?.status === 200 || response?.status === 201) {
                toast.success("Request Added Successfully");
                resetForm();
            } else {
                const { errorCode } = response?.data; 
                if (errorCode === "missing_mandate_field") {
                    toast.error("Please fill mandatory fields");
                } else {
                    toast.error("Error while material request!!");
                }
            }
        } else {
            toast.error("Please fill mandatory fields");
        }
    };

    const getWorkOrderDetails = async () => {
        setIsLoading(true);
        const orderResponse = await APIService.get(`${orderApi.get}${orderId}`);
        if (orderResponse?.order) {
            setOrder(orderResponse.order);
        };
        setIsLoading(false);
    };

    const addMoreProducts = () => {
        setFormList([...formList, INITIAL_FORM_VALUE]);
        setFormErrorList([...formErrorList, ERROR_MESSAGE_VALUES]);
    };

    const removeProduct = (index) => {
        const updatedFormList = formList.filter((form, i) => i !== index);
        const updatedFormErrorList = formErrorList.filter((form, i) => i !== index);
        setFormList(updatedFormList);
        setFormErrorList(updatedFormErrorList);
    }

    const getDetails = () => {
        setIsLoading(true);
        if (data.productInventory) {
            if (orderId) {
                getWorkOrderDetails();
            }
        }
    };

    useEffect(() => {
        getDetails();
    }, [data]);

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader 
                        title="Raise Material Request"
                        description={`${mode === "edit" ? "Update" : "Add"} Request`}
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                    />
                    {data?.productInventory && formList.map((formValues, index) => (
                        <>
                            <span className="text-[14px] text-red-500 font-small pt-3.5 pl-3.5">Work Order:  {order.orderId}</span>
                            <div key={`${index}_parent_div`} id={`${index}_parent_div`} className="m-4 flex flex-col rounded h-auto bg-white">
                                <div className="grid grid-cols-4 gap-4 p-5" key={`${index}_child_div`}>
                                    <div key={`name_${index}`}>
                                        <Dropdown
                                            options={data.productInventory ?? []}
                                            selectedOption={selectedOption(get(formValues, "name"), data.productInventory, "name")}
                                            onOptionSelect={(option, id) => handleOptionSelect(index, option, id)}
                                            label="Product Name"
                                            name="Product Name"
                                            id="name"
                                            required={true}
                                        />
                                        {get(formErrorList[index], "name") && <ErrorMessage />}
                                    </div>
                                    <div key={`code_${index}`}>
                                        <InputField
                                            name="code"
                                            label="Code"
                                            value={get(formValues, "code")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                        />
                                        {get(formErrorList[index], "code") && <ErrorMessage />}
                                    </div>
                                    <div key={`description_${index}`}>
                                        <InputField
                                            name="description"
                                            label="Description"
                                            value={get(formValues, "description")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                        />
                                        {get(formErrorList[index], "description") && <ErrorMessage />}
                                    </div>
                                    <div key={`materialType_${index}`}>
                                        <InputField
                                            name="materialType"
                                            label="Material / Goods Type"
                                            value={get(formValues, "materialType")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                        />
                                        {get(formErrorList[index], "materialType") && <ErrorMessage />}
                                    </div>
                                    <div key={`stockType_${index}`}>
                                        <InputField
                                            name="stockType"
                                            label="Stock Type"
                                            value={get(formValues, "stockType")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                        />
                                        {get(formErrorList[index], "stockType") && <ErrorMessage />}
                                    </div>
                                    <div key={`quantity_${index}`}>
                                        <InputField
                                            name="quantity"
                                            label="Quantity"
                                            value={get(formValues, "quantity")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                        />
                                        {get(formErrorList[index], "quantity") && <ErrorMessage error={formErrorList[index].quantity} />}
                                    </div>
                                    <div key={`unitOfMeasurement_${index}`}>
                                        <InputField
                                            name="unitOfMeasurement"
                                            label="Unit"
                                            value={get(formValues, "unitOfMeasurement")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={true}
                                            disabled={true}
                                        />
                                        {get(formErrorList[index], "unitOfMeasurement") && <ErrorMessage />}
                                    </div>
                                    <div key={`additionalInfo_${index}`}>
                                        <InputField
                                            name="additionalInfo"
                                            label="Additional Information"
                                            placeholder="Additional Information"
                                            value={get(formValues, "additionalInfo")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={false}
                                        />
                                    </div>
                                    <div key={`remark_${index}`}>
                                        <InputField
                                            name="remark"
                                            label="Remark"
                                            placeholder="Remark"
                                            value={get(formValues, "remark")}
                                            onChange={event => handleInputChange(index, event)}
                                            required={false}
                                        />
                                        {get(formErrorList[index], "unitOfMeasurement") && <ErrorMessage />}
                                    </div>
                                </div>
                                {formList.length > 1 && <>
                                    <div className="flex pl-5 pb-2">
                                        <Button text="Remove" textColor="white" bgColor="red-500" onClick={() => removeProduct(index)} />
                                    </div>
                                </>}
                            </div>
                        </>
                    ))}
                    <div className="grid grid-cols-4 gap-4 p-3">
                        {data?.productInventory && !isPending && <>
                        <div className="flex p-2.5 gap-2">
                            <Button text={`${mode === "edit" ? "Update" : "Raise"} Request`} textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                            {/* <Button text="Add Product" textColor="white" bgColor="[#39ACE4]" onClick={() => addMoreProducts()} /> */}
                            <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                        </div></>}
                        {isPending && <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                        />}
                    </div>
                </>
            )}
        </>
    );
}

export default RaiseMaterialRequest;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { set, cloneDeep, get, isEmpty, omit, without } from "lodash";

import InputField from "@/components/InputField";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";
import { INITIAL_USER_FORM_VALUE, USER_ERROR_MESSAGE_VALUES, USER_REQUIRED_FIELDS } from "./constant";
import { REGEX } from "@/utils/AppConstants";

Modal.setAppElement("#root");

const AddUserPopup = ({ toggleUserPopup, isOpen, onAddUserSubmit, selectedRow, roleData }) => {
    const [addUserData, setAddUserData] = useState(!isEmpty(selectedRow) ? selectedRow : INITIAL_USER_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(USER_ERROR_MESSAGE_VALUES);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const roleList = roleData?.map(item => {
        return { label: item?.name, value: item?.id }
    });
    const modeTxt = !isEmpty(selectedRow) ? "Edit" : "Add";

    const handleSelectChange = (selectedValues) => {
        //const selectedRoles = selectedValues?.map(item => item.value);
        const selectedRoles = selectedValues?.value;
        setAddUserData((prevState) => ({
            ...prevState,
            roleId: [selectedRoles],
        }));
        setSelectedRoles([selectedValues]);
        console.log(addUserData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(addUserData);
        set(updatedState, name, value);
        setAddUserData(updatedState);
    };

    const handleSubmit = async () => {
        const newErrorMessages = {};
        const REQUIRED_FIELDS = (modeTxt === "Edit") ? without(USER_REQUIRED_FIELDS, "passCode") : USER_REQUIRED_FIELDS;
        REQUIRED_FIELDS.forEach((field) => {
            if (!get(addUserData, field) || isEmpty(get(addUserData, field))) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "contactMedium.email" && !get(addUserData, field).toLowerCase().match(REGEX.EMAIL)) {
                    newErrorMessages[field] = "Please enter valid email address";
                };

                if (field === "contactMedium.phoneNumber" && !get(addUserData, field).toLowerCase().match(REGEX.PHONE)) {
                    newErrorMessages[field] = "Please enter valid phone number";
                };

                if (field === "passCode" && get(addUserData, field).length < 6) {
                    newErrorMessages[field] = "Please enter minimum 6 characters password";
                };
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            return;
        }

        const action = !isEmpty(selectedRow) ? "edit" : "";
        let updatedUserObject;
        if (action === "edit") {
            updatedUserObject = {
                familyName: addUserData.familyName,
                givenName: addUserData.givenName,
                contactMedium: addUserData.contactMedium,
                roleId: addUserData.roleId,
                userId: addUserData.userId
            };
        } else {
            updatedUserObject = { ...addUserData };
        }
        delete updatedUserObject.fullName;
        onAddUserSubmit(updatedUserObject, action);
    }

    useEffect(() => {
        const selectedRoles = roleList?.filter(item => addUserData?.roleId?.includes(item?.value));
        setSelectedRoles(selectedRoles);
    }, []);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleUserPopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg w-4/12"
            >
                <div>
                    <span className="text-xl font-small pt-3.5">{modeTxt} User</span>
                    <div className="grid grid-cols-2 gap-2 pt-3.5">
                        <div className="mb-2">
                            <InputField
                                name="familyName"
                                label="First Name"
                                placeholder="First Name"
                                value={addUserData?.familyName}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {errorMessages.familyName && <p className="text-red-500 text-[12px] mt-1">{errorMessages.familyName}</p>}
                        </div>
                        <div className="mb-2">
                            <InputField
                                name="givenName"
                                placeholder="Last Name"
                                label="Last Name"
                                value={addUserData?.givenName}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {errorMessages.givenName && <p className="text-red-500 text-[12px] mt-1">{errorMessages.givenName}</p>}
                        </div>
                        <div className="mb-2">
                            <InputField
                                name="userId"
                                placeholder="User Name"
                                label="User Name"
                                value={addUserData?.userId}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {errorMessages.userId && <p className="text-red-500 text-[12px] mt-1">{errorMessages.userId}</p>}
                        </div>
                        <div className="mb-2">
                            <InputField
                                name="contactMedium.email"
                                placeholder="Email"
                                label="Email"
                                value={addUserData["contactMedium"]?.email}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {get(errorMessages, "contactMedium.email") && <p className="text-red-500 text-[12px] mt-1 text-left">{errorMessages["contactMedium.email"]}</p>}
                        </div>
                        { (modeTxt === "Add") &&
                            <div className="mb-2">
                                <InputField
                                    type="password"
                                    name="passCode"
                                    placeholder="Password"
                                    label="Password"
                                    value={addUserData?.passCode}
                                    onChange={(e) => handleInputChange(e)}
                                    autoComplete="off"
                                    required={true}
                                />
                                {errorMessages.passCode && <p className="text-red-500 text-[12px] mt-1">{errorMessages.passCode}</p>}
                            </div>
                        }
                        <div className="mb-2">
                            <InputField
                                name="contactMedium.phoneNumber"
                                placeholder="Phone Number"
                                label="Phone Number"
                                value={addUserData["contactMedium"]?.phoneNumber}
                                onChange={(e) => handleInputChange(e)}
                                required={true}
                            />
                            {get(errorMessages, "contactMedium.phoneNumber") && <p className="text-red-500 text-[12px] mt-1 text-left">{errorMessages["contactMedium.phoneNumber"]}</p>}
                        </div>
                    </div>
                    <div className="mb-2">
                        <p className='block text-black text-[12px]'>Role Id <span className="text-red-500 text-[12px] font-extrabold">*</span></p>
                        <Select
                            options={roleList}
                            value={selectedRoles}
                            name="roleId"
                            placeholder="Select roles..."
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: '12px',
                                    borderColor: 'rgb(156 163 175)'
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: '12px',
                                })
                            }}
                            onChange={handleSelectChange}
                            isSearchable={true}
                            required={true}
                        />
                        {errorMessages.roleId && <p className="text-red-500 text-[12px] mt-1">{errorMessages.roleId}</p>}
                    </div>
                </div>
                <div className="flex gap-2">
                    <Button text="Submit" textColor="white" bgColor="[#1A91D1]" onClick={() => handleSubmit()} />
                    <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => toggleUserPopup()} />
                </div>
            </Modal>
        </div>
    );
};

export default AddUserPopup;

import React, { useEffect, useState, useRef } from "react";
import { Blocks } from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useSearchParams } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server'
import { findIndex, isEmpty, omit, without } from "lodash";

import { useData } from "@/hooks/useData";
import { useAuth } from "@/hooks/useAuth";
import { APIService } from '@/services';
import { userApi, enquireApi, enquireQuatationApi, organizationApi, pdfGenerator } from "@/utils/API";

import Button from "@/components/Button";
import ContentHeader from "../ContentHeader";
import PrintQuotation from "./PrintQuotation";

const headerButton = { showBackBtn: true };

const ViewQuotation = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const { user: auth } = useAuth();
    const { data } = useData();
    const componentRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [quotation, setQuotation] = useState({});
    const [enquiry, setEnquiry] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [organization, setOrganization] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [isPrint, setIsPrint] = useState(false);

    const quotationId = queryParams.get("quotationId");
    const enquiryId = queryParams.get("enquireId");

    const emailQuotation = async () => {
        let htmlString = await renderToStaticMarkup(
            <PrintQuotation
                ref={componentRef}
                user={userDetails}
                enquiry={enquiry}
                quotation={quotation}
                organization={organization}
                handlePrint={handlePrint}
                selectedRows={selectedRows}
                handleRowSelection={handleRowSelection}
                isPrint={true}
            />
        );
        // console.log(htmlString);
        //const response = await APIService.post(pdfGenerator.post, htmlString);
        //console.log(response)
        
    }
        
    const takePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = () => {
        setIsPrint(true);
        setTimeout(() => {
            takePrint();
        }, 100);
    };

    const handleRowSelection = (event) => {
        const { name, checked } = event.target;
        if (name !== "all") {
            let updatedRows;
            if (checked && (selectedRows.indexOf(name) === -1)) {
                updatedRows = [...selectedRows, name]
            } else {
                updatedRows = without(selectedRows, name, "all");
            }
            setSelectedRows(updatedRows);
        } else if (name == "all" && checked) {
            const updatedRows = [
                "all",
                "length",
                "mainSpineIBeam",
                "axlesMake",
                "suspension",
                "breakingSystem",
                "tyre",
                "wheelRim",
                "crossMember",
                "paddingPlate",
                "fishPlate",
                "sideRave",
                "toolBox",
                "floorSheet",
                "tailDoorWindow",
                "kingpin",
                "landingLag",
                "spareWheelCarrierBkt",
                "supd",
                "tieDownHook",
                "lashingPate",
                "coilLashing",
                "taperSupport",
                "electrical",
                "surfaceTreatment",
                "reflectiveTape",
                "colourShade",
                "fabrication",
                "additionalInformation",
            ];
            setSelectedRows(updatedRows);
        } else {
            setSelectedRows([]);
        }
    };

    const getEnquiry = async () => {
        const response = await APIService.get(`${enquireApi.get}${enquiryId}`);
        setEnquiry(omit(response?.enquiry,["quotationList"]) || {});
    };

    const getQuotation = async () => {
        const quotationResponse = await APIService.get(`${enquireQuatationApi.get}${quotationId}`);
        const quotation = quotationResponse?.quotation;
        setQuotation(quotation);

        const userUrl = `${userApi.fetchUserById}?fields=givenName,familyName,contactMedium&filterAnd=userId%7Ceq%7C${quotation.createdBy}&limit=1&offset=0`
        const userResponse = await APIService.get(userUrl);
        setUserDetails(userResponse?.userList[0] || {});
        setIsLoading(false);
    }

    const getOrganization = async () => {
        setIsLoading(true);
        setOrganization(data.organization?.organizationList[0]);
    };

    useEffect(() => {
        if (data.orderForm && quotationId !== "0") {
            getOrganization();
            getEnquiry();
            getQuotation();
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <div className='flex items-center justify-center'>
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
            </div>
            ) : (
                <>
                    <ContentHeader 
                        title="Quotation"
                        description="Quotation"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={true}
                        btnToShow={headerButton}
                    />
                    {!isEmpty(quotation) &&  <>
                        <PrintQuotation
                            ref={componentRef}
                            user={userDetails}
                            enquiry={enquiry}
                            quotation={quotation}
                            organization={organization}
                            handlePrint={handlePrint}
                            selectedRows={selectedRows}
                            handleRowSelection={handleRowSelection}
                            isPrint={isPrint}
                        />
                        { selectedRows.length > 0 &&
                            <div className="flex justify-center">
                                <div className="m-2">
                                    <Button text="Back" textColor="white" bgColor="[#1A91D1]" onClick={() => navigate(-1)} />
                                </div>
                                <div className="m-2">
                                    <Button text="Print Quotation" textColor="white" bgColor="[#1A91D1]" onClick={() => handlePrint()} />
                                </div>
                                { false &&
                                <div className="m-2">
                                    <Button text="Email Quotation" textColor="white" bgColor="[#1A91D1]" onClick={() => emailQuotation()} />
                                </div>}
                            </div>
                        }
                        {   selectedRows.length === 0 &&
                            <div className="flex justify-center m-2">
                                <span className="text-red-500 text-[14px] font-semibold">Please select rows to print</span>
                            </div>
                        }
                    </>}
                </>
            )}
        </>
    );
}

export default ViewQuotation;

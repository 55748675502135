import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Blocks } from "react-loader-spinner";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import TableCustomFilter from "@/components/TableCustomFilter";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "../../ContentHeader";
import AddWorkOrderProductionStatusPopup from "./AddWorkOrderProductionStatusPopup";

import { useData } from "@/hooks/useData";
import { APIService } from "@/services";
import { productionApi, orderApi } from "@/utils/API";
import { addSerialNumbersToList } from "@/utils/CustomFunctions";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";
import DailyStatusAccordion from './DailyStatusAccordion/DailyStatusAccordion';

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const WorkOrderProductionStatusList = () => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();

    const { data } = useData();
    const [isLoading, setIsLoading] = useState(false);
    const [productionList, setProductionList] = useState([]);
    const [originalProductionList, setOriginalProductionList] = useState([]);
    const [isListChanged, setIsListChanged] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});
    const [order, setOrder] = useState({});
    const [statusList, setStatusList] = useState([]);
    const [isAddStatusPopupOpen, setIsAddStatusPopupOpen] = useState(false);
    
    const orderId = queryParams.get("workOrderId");
    const orderIdLabel = queryParams.get("labelId");

    const togglePopup = () => {
        setIsAddStatusPopupOpen(!isAddStatusPopupOpen);
        setSelectedRow({});
    };

    const selectDailyStatus = (index) => {
        setSelectedStatus(selectedStatus === index ? null : index);
    };

    const getWorkOrderDetails = async () => {
        setIsLoading(true);
        const orderResponse = await APIService.get(`${orderApi.get}${orderId}`);
        if (!isEmpty(orderResponse?.order)) {
            setOrder(orderResponse.order);
        }

        if (!isEmpty(data.orderStatus)) {
            setStatusList(data.orderStatus);
        }

        setIsLoading(false);
    };

    const getProductionDailyUpdatesList = async () => {
        setIsLoading(true);
        const response = await APIService.get(productionApi.getProductionListByOrderId(orderIdLabel));
        if (!isEmpty(response?.productionList)) {
            if (!isEmpty(response.productionList[0]?.dailyActivities)) {
                const sortedByDate = response.productionList[0].dailyActivities.sort(function(a, b){
                    return new Date(b.createdDate) - new Date(a.createdDate);
                });
                setProductionList(sortedByDate);
                setOriginalProductionList(sortedByDate);
                // console.log(sortedByDate);
            }
        }
        setIsLoading(false);
    };

    const onAddDailyStatusSubmit = async (payload, action = "") => {
        let response;
        if (action === "") {
            response = await APIService.post(productionApi.post, payload);
        } else {
            response = await APIService.patch(`${productionApi.patch}`, payload);
        }

        if (response?.status === 200 || response?.status === 201) {
            if (response?.data.userId !== "" && action !== "edit") {
                toast.success("Order status added successfully!");
            } else {
                toast.success("Order status updated successfully!");
            }
        } else {
            if (response?.message) {
                toast.error(response?.message);
            }
        }
        
        setIsListChanged(!isListChanged)
        setSelectedRow({});
        setIsAddStatusPopupOpen(false);
    };

    const onEditStatus = (data) => {
        setSelectedRow(data);
        setIsAddStatusPopupOpen(!isAddStatusPopupOpen);
        console.log(data);
    };

    const filterDataFunction = (searchInput) => {
    };

    const viewMaterialRequest = () => {
        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.MAT_REQ_LIST}?workOrderId=${orderIdLabel}`);
    }

    useEffect(() => {
        if (data.orderStatus) {
            getWorkOrderDetails();
        }
    }, [data]);

    useEffect(() => {
        getProductionDailyUpdatesList();
    }, [isListChanged])

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <ContentHeader
                        title="Enquiry"
                        description="Enquiry List"
                        showHeader={true}
                        showDatePicker={false}
                        showSearchInput={false}
                        showButton={false}
                        btnToShow={headerButton}
                        filterDataFunction={filterDataFunction}
                    />

                    <div className="m-4 flex flex-col rounded h-auto bg-white">

                        {isAddStatusPopupOpen && <AddWorkOrderProductionStatusPopup togglePopup={togglePopup} isOpen={isAddStatusPopupOpen} onAddDailyStatusSubmit={onAddDailyStatusSubmit} order={order} statusList={statusList} selectedRow={selectedRow} />}

                        <div className="flex justify-between items-center mt-4 ml-3 mr-5">
                            <div className="flex space-x-3">
                                {/* <TableCustomFilter filterDataFunction={filterDataFunction} /> */}
                            </div>
                            <span className="text-[14px] text-red-500 font-small pt-3.5 pl-3.5">Work Order:  {orderIdLabel}</span>
                            <div className="flex">
                                <div className="pr-[5px]">
                                    <Button text="View Material Request" textColor="white" bgColor="[#39ACE4]" onClick={viewMaterialRequest} />
                                </div>
                                <div className="pr-[5px]">
                                    <Button text="Add Daily Status" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={togglePopup} />
                                </div>
                                <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                            </div>
                        </div>

                        <div className="p-2 mt-2">
                            {productionList.map((value, i) => (
                                <div onClick={() => selectDailyStatus(i)}>
                                    <DailyStatusAccordion active={selectedStatus === i} data={value} onEditStatus={onEditStatus} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default WorkOrderProductionStatusList;